<template>
  <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">{{ btn_text }}</v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>{{ title }}</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <div class="text-h5">{{ content }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field type="number" min="0" outlined label="" @input="emitInput" :value="value"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field outlined label="Referencia" v-model="reference"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <CapsVTextArea outlined label="Comentario" v-model="observation"></CapsVTextArea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Cerrar</v-btn>
          <v-col v-if="currentText !== '0'">
            <v-btn v-if="isLoad" dark class="green lighten-2" @click="accept(1)">Entrada</v-btn>
            <v-btn v-else dark class="orange lighten-2" @click="accept(-1)">Salida</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  methods: {
    accept() {
      const qty = this.isLoad ? this.currentText : -(parseInt(this.currentText))
      this.$emit('accept', {
        qty,
        observation: this.observation,
        reference: this.reference,

      })
      this.dialog = false
    },
    emitInput(text) {
      this.currentText = text
      const qty = this.currentText
      this.$emit('input', qty)
    }
  },
  data() {
    return {
      dialog: false,
      reference: null,
      observation: null,
      currentText: null
    }
  },
  props: ['value', 'title', 'btn_text', 'content', 'isLoad']
}
</script>