<template>
  <v-container>
    <h2>GRUPOS DE SELECTIVO</h2>
    <CRUDIndex :permissionRoot="'Grupos de Selectivo'" :headers="headers" :storeModel="storeModel"
      :storeModelPlural="storeModelPlural" :extraParams="{ isSelectiveGroup: true }" :routeCreate="'DoyangGroupCreate'"
      :routeEdit="'DoyangGroupEdit'" :onCreatedRedirect="'SelectiveGroupIndex'" :items=items
      :permissionRouteAddPeople="'Asistencias'" :routeAddPeople="'DoyangGroupAddPeople'" />
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  mounted() {
    this.$store.dispatch('DoyangGroup/index', '?is_selective_group=1')
  },
  computed: {
    items() {
      return this.$store.getters['DoyangGroup/doyangGroups']
    }
  },
  data() {
    return {
      headers: [
        { text: 'Nombre', value: 'name' },
      ],
      storeModel: 'SelevtiveGroup',
      storeModelPlural: 'SelectiveGroups',
    }
  }
}
</script>

<style></style>e
