<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>GRUPOS EN {{ selectedDoyang ? selectedDoyang.name : '' }}</h2>
        <v-col v-if="bimesterStart && bimesterEnd">
          <h3>BIMESTRE {{ ENV.__parseDateToText(bimesterStart) }}-{{ ENV.__parseDateToText(bimesterEnd) }}</h3>
        </v-col>
        <CRUDIndex :permissionRoot="'Doyang Grupos'" :permissionRouteAddPeople="'Asistencias'"
          :routeAddPeople="'DoyangGroupAddPeople'" :showWhatsApp="true" :headers="headers" :sortBy="['begins_at']"
          :sortDesc="[false]" :onCreatedRedirect="'DoyangGroupIndex'" :createParentId="+selectedDoyang.id"
          :payload="(selectedDoyang ? `?doyang_id=${selectedDoyang.id}&with=responsible,certifier&orderBy[ends_at]=asc` : '?with=responsible,certifier&orderBy[ends_at]=asc')"
          :storeModel="storeModel" :itemsPerPage="itemsPerPage" :groupBy="groupBy" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ENV from '../../../env'
import CRUDIndex from '../globals/CRUDIndex'
export default {
  props: {
    doyangId: Number
  },
  components: {
    CRUDIndex
  },
  computed: {
    selectedDoyang() {
      return this.$store.getters['Auth/selectedDoyang']
    },
  },
  mounted() {
    this.$store.dispatch('Configuration/show', 1).then((res) => {
      this.bimesterStart = res.value
    })
    this.$store.dispatch('Configuration/show', 2).then((res) => {
      this.bimesterEnd = res.value
    })
  },
  methods: {
  },
  data() {
    return {
      bimesterStart: null,
      bimesterEnd: null,
      search: '',
      ENV,
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Alumnos', value: 'students_count' },
        { text: 'Horario', value: 'schedule' },
        { text: 'Responsable', value: 'responsible.full_name_honorific' },
        { text: 'Certificador', value: 'certifier.full_name_honorific' },
      ],
      groupBy: ['short_json_week'],
      storeModel: 'DoyangGroup',
      itemsPerPage: 20
    }
  },
}
</script>

<style></style>
