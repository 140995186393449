import Vue from 'vue'
import axios from 'axios'
import store from '../store/index'
import VueRouter from 'vue-router'
import ChooseHome from '../views/ChooseHome.vue'
import UserPassword from '../components/users/Password.vue'
import SinodalCreate from '../components/sinodals/Create.vue'
import SinodalIndex from '../components/sinodals/Index.vue'
import StudentCreate from '../components/students/Create.vue'
import StudentIndex from '../components/students/Index.vue'
import ProfessorIndex from '../components/professors/Index.vue'
import ProfessorCreate from '../components/professors/Create.vue'
import VenueIndex from '../components/venues/Index.vue'
import VenueCreate from '../components/venues/Create.vue'
import EventIndex from '../components/events/Index.vue'
import EventCreate from '../components/events/Create.vue'
import EventProspects from '../components/events/Prospects.vue'
import ExamIndex from '../components/exams/Index.vue'
import ExamCreate from '../components/exams/Create.vue'
import ExamProspects from '../components/exams/Prospects.vue'
import DoyangIndex from '../components/doyangs/Index.vue'
import DoyangCreate from '../components/doyangs/Create.vue'
import DegreeIndex from '../components/degrees/Index.vue'
import DegreeCreate from '../components/degrees/Create.vue'
import DiscountIndex from '../components/discounts/Index.vue'
import DiscountCreate from '../components/discounts/Create.vue'
import DoyangCategoryRequisiteCreate from '../components/doyang_cateogory_requisistes/Create.vue'
import DoyangAttendance from '../components/attendances/DoyangAttendance.vue'
import DoyangGroupAttendance from '../components/attendances/DoyangGroupAttendance.vue'
import HonorificTitleIndex from '../components/honorific_titles/Index'
import HonorificTitleCreate from '../components/honorific_titles/Create'
import CityIndex from '../components/cities/Index'
import CityCreate from '../components/cities/Create'
import ProductIndex from '../components/products/Index'
import ProductCreate from '../components/products/Create'
import TournamentIndex from '../components/tournaments/Index'
import TournamentCreate from '../components/tournaments/Create'
import TournamentProspects from '../components/tournaments/Prospects.vue'
import PaymentCreate from '../components/payments/Create.vue'
import ExpenseCreate from '../components/expenses/Create.vue'
import ExpenseIndex from '../components/expenses/Index.vue'
import InventoryIndex from '../components/inventory/Index.vue'
import InventoryCreate from '../components/inventory/Create.vue'
import DoyangCategoryIndex from '../components/doyang_categories/Index.vue'
import DoyangCategoryCreate from '../components/doyang_categories/Create.vue'
import DoyangGroupIndex from '../components/doyang_groups/Index.vue'
import DoyangGroupCreate from '../components/doyang_groups/Create.vue'
import DoyangGroupAddPeople from '../components/doyang_groups/AddPeople.vue'
import KyosaList from '../components/doyang_groups/KyosaList.vue'
import MonthlyDutyIndex from '../components/monthly_duties/Index.vue'
import MonthlyDutyCreate from '../components/monthly_duties/Create.vue'
import RequirementIndex from '../components/requirements/Index.vue'
import RequirementCreate from '../components/requirements/Create.vue'
import ProductVariantCreate from '../components/product_variants/Create.vue'
import ProductSizeCreate from '../components/product_sizes/Create.vue'
import PaymentIndex from '../components/payments/Index.vue'
import InventoryMoveIndex from '../components/inventory_moves/Index.vue'
import DoyangSurrogationIndex from '../components/doyang_surrogations/Index.vue'
import DoyangSurrogationCreate from '../components/doyang_surrogations/Create.vue'
import MultilevelDiscountTypeIndex from '../components/multilevel_discount_types/Index.vue'
import MultilevelDiscountTypeCreate from '../components/multilevel_discount_types/Create.vue'
import DoyangBundleCreate from '../components/doyang_bundles/Create.vue'
import PermissionIndex from '../components/permissions/Index.vue'
import ConfigurationIndex from '../components/configuration/Index.vue'
import ReceiptIndex from '../components/receipts/Index.vue'
import OtherExamRequisiteIndex from '../components/other_exam_requisites/Index.vue'
import OtherExamRequisiteCreate from '../components/other_exam_requisites/Create.vue'
import InsuranceIndex from '../components/insurances/InsuranceIndex.vue'
import GeneralFileResourcesIndex from '../components/general_file_resources/Index.vue'
import GeneralFileResourcesCreate from '../components/general_file_resources/Create.vue'
import TournamentCategoriesIndex from '../components/tournament_categories/Index.vue'
import TournamentCategoriesCreate from '../components/tournament_categories/Create.vue'
import TournamentGraphs from '../components/tournaments/TournamentGraphs.vue'
import Login from '../components/login/Login.vue'
import ParentEventIndex from '../components/parent_events/Index'
import ParentEventCreate from '../components/parent_events/Create'
import Statistics from '../components/statistics/Statistics.vue'
import GenealogyIndex from '../components/genealogy/Index.vue'
import PoomseIndex from '../components/poomses/Index.vue'
import PoomseCreate from '../components/poomses/Create.vue'
import SelectiveGroupIndex from '../components/group_for_selective/Index.vue'
import SelectiveGroupCreate from '../components/group_for_selective/Create.vue'
import { debounce } from 'debounce'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ChooseHome
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  //Configuración
  {
    path: '/configuration',
    name: 'ConfigurationIndex',
    component: ConfigurationIndex
  },
  //Permissions
  {
    path: '/permissions',
    name: 'PermissionIndex',
    component: PermissionIndex
  },
  //User
  {
    path: '/requirements',
    name: 'RequirementIndex',
    component: RequirementIndex
  },
  {
    path: '/requirements/create',
    name: 'RequirementCreate',
    component: RequirementCreate
  },
  {
    path: '/requirements/:id/edit',
    name: 'RequirementEdit',
    component: RequirementCreate
  },
  // Genealogy
  {
    path: '/genealogy',
    name: 'GenealogyIndex',
    component: GenealogyIndex
  },
  //User
  {
    path: '/user/password',
    name: 'UserPassword',
    component: UserPassword
  },
  //CRUD FOR DOYANG SURROGATION
  {
    path: '/statistics/doyang/:id',
    name: 'Statistics',
    component: Statistics
  },
  //CRUD FOR DOYANG SURROGATION
  {
    path: '/doyang_surrogations',
    name: 'DoyangSurrogationIndex',
    component: DoyangSurrogationIndex
  },
  {
    path: '/doyang_surrogations/create',
    name: 'DoyangSurrogationCreate',
    component: DoyangSurrogationCreate
  },
  {
    path: '/doyang_surrogations/:id/edit',
    name: 'DoyangSurrogationEdit',
    component: DoyangSurrogationCreate
  },
  //CRUD FOR MULTILEVEL DISCOUNT TYPES
  {
    path: '/multilevel_discount_types',
    name: 'MultilevelDiscountTypeIndex',
    component: MultilevelDiscountTypeIndex
  },
  {
    path: '/multilevel_discount_types/create',
    name: 'MultilevelDiscountTypeCreate',
    component: MultilevelDiscountTypeCreate
  },
  {
    path: '/multilevel_discount_types/:id/edit',
    name: 'MultilevelDiscountTypeEdit',
    component: MultilevelDiscountTypeCreate
  },
  //CRUD FOR DOYANG receipts
  {
    path: '/receipts',
    name: 'ReceiptIndex',
    component: ReceiptIndex
  },
  {
    path: '/receipts/create',
    name: 'ReceiptCreate',
    component: ReceiptIndex
  },
  //CRUD FOR DOYANG CATEGORIES
  {
    path: '/doyang_categories',
    name: 'DoyangCategoryIndex',
    component: DoyangCategoryIndex
  },
  // DOYANG BUNDLES
  {
    path: '/doyang_bundles/create',
    name: 'DoyangBundleCreate',
    component: DoyangBundleCreate,
    props: true
  },
  {
    path: '/doyang_bundles/:id/edit',
    name: 'DoyangBundleEdit',
    component: DoyangBundleCreate,
    props: true
  },
  //CRUD DOYANG GROUPS
  {
    path: '/doyang_groups',
    name: 'DoyangGroupIndex',
    component: DoyangGroupIndex
  },
  {
    path: '/doyang_groups/:id/add_people',
    name: 'DoyangGroupAddPeople',
    component: DoyangGroupAddPeople
  },{
    path: '/doyang_groups/:id/kyosa_list',
    name: 'KyosaList',
    component: KyosaList
  },
  {
    path: '/doyang_groups/:id/edit',
    name: 'DoyangGroupEdit',
    component: DoyangGroupCreate,
    props: true
  },
  {
    path: '/doyang_groups/create',
    name: 'DoyangGroupCreate',
    component: DoyangGroupCreate,
    props: true
  },
  //CRUD DOYANG CATEGORIES
  {
    path: '/doyang_categories/create',
    name: 'DoyangCategoryCreate',
    component: DoyangCategoryCreate
  },
  {
    path: '/doyang_categories/:id/edit',
    name: 'DoyangCategoryEdit',
    component: DoyangCategoryCreate
  },
  //CRUD FOR INVENTORY
  {
    path: '/doyang/:id/inventory',
    name: 'InventoryIndex',
    component: InventoryIndex
  },
  {
    path: '/product/:id/inventory',
    name: 'InventoryCreate',
    component: InventoryCreate
  },
  //CRUD FOR POOMSE
  {
    path: '/poomses',
    name: 'PoomseIndex',
    component: PoomseIndex
  },
  {
    path: '/poomses/create',
    name: 'PoomseCreate',
    component: PoomseCreate
  },
  {
    path: '/poomses/:id/edit',
    name: 'PoomseEdit',
    component: PoomseCreate
  },
  //CRUD FOR EXPENSES
  {
    path: '/expenses',
    name: 'ExpenseIndex',
    component: ExpenseIndex
  },
  {
    path: '/expenses/create',
    name: 'ExpenseCreate',
    component: ExpenseCreate
  },
  {
    path: '/expenses/:id/edit',
    name: 'ExpenseEdit',
    component: ExpenseCreate
  },
  //CRUD FOR PAYMENTS
  {
    path: '/payments/create',
    name: 'PaymentCreate',
    component: PaymentCreate
  },
  {
    path: '/payments',
    name: 'PaymentIndex',
    component: PaymentIndex
  },
  //CRUD FOR TOURNAMENTS
  {
    path: '/tournaments/:id/graphs',
    name: 'TournamentGraphs',
    component: TournamentGraphs
  },
  {
    path: '/tournaments/create',
    name: 'TournamentCreate',
    component: TournamentCreate
  },
  {
    path: '/tournaments/:id/edit',
    name: 'TournamentEdit',
    component: TournamentCreate
  },
  {
    path: '/tournaments',
    name: 'TournamentIndex',
    component: TournamentIndex
  },
  {
    path: '/tournaments/:id/prospects',
    name: 'TournamentProspects',
    component: TournamentProspects
  },
  {
    path: '/tournament_categories',
    name: 'TournamentCategoryIndex',
    component: TournamentCategoriesIndex
  },
  {
    path: '/tournament_categories/create',
    name: 'TournamentCategoryCreate',
    component: TournamentCategoriesCreate
  },
  {
    path: '/tournament_categories/:id/edit',
    name: 'TournamentCategoryEdit',
    component: TournamentCategoriesCreate
  },

  //CRUD FOR CITIES
  {
    path: '/cities/create',
    name: 'CityCreate',
    component: CityCreate
  },
  {
    path: '/cities/:id/edit',
    name: 'CityEdit',
    component: CityCreate
  },
  {
    path: '/cities',
    name: 'CityIndex',
    component: CityIndex
  },
  //CRUD FOR PRODUCTS
  {
    path: '/products/create',
    name: 'ProductCreate',
    component: ProductCreate
  },
  {
    path: '/products/:id/edit',
    name: 'ProductEdit',
    component: ProductCreate
  },
  {
    path: '/products',
    name: 'ProductIndex',
    component: ProductIndex
  },
  //CRUD FOR HONORIFIC TITLES
  {
    path: '/honorific_titles/create',
    name: 'HonorificTitleCreate',
    component: HonorificTitleCreate
  },
  {
    path: '/honorific_titles/:id/edit',
    name: 'HonorificTitleEdit',
    component: HonorificTitleCreate
  },
  {
    path: '/honorific_titles',
    name: 'HonorificTitleIndex',
    component: HonorificTitleIndex
  },
  //CRUD FOR DOYANGS
  {
    path: '/doyangs/:id/attendances',
    name: 'DoyangAttendance',
    component: DoyangAttendance
  },
  //CRUD DOYANG GROUPS
  {
    path: '/doyang_groups/:id/attendances',
    name: 'DoyangGroupAttendance',
    component: DoyangGroupAttendance
  },
  //CRUD DOYANGS
  {
    path: '/doyangs',
    name: 'DoyangIndex',
    component: DoyangIndex
  },
  {
    path: '/doyangs/create',
    name: 'DoyangCreate',
    component: DoyangCreate
  },
  {
    path: '/doyangs/:id/edit',
    name: 'DoyangEdit',
    component: DoyangCreate
  },
  //CRUD PRODUCT VARIANTS
  {
    path: '/product_variants/create',
    name: 'ProductVariantCreate',
    component: ProductVariantCreate,
    props: true
  },
  {
    path: '/product_variants/:id/edit',
    name: 'ProductVariantEdit',
    component: ProductVariantCreate,
    props: true
  },
  //CRUD PRODUCT SIZES
  {
    path: '/product_sizes/create',
    name: 'ProductSizeCreate',
    component: ProductSizeCreate,
    props: true
  },
  {
    path: '/product_sizes/:id/edit',
    name: 'ProductSizeEdit',
    component: ProductSizeCreate,
    props: true
  },
  //CRUD DOYANGS
  // {
  //   path: '/doyang_category_requisites',
  //   name: 'DoyangCategoryRequisiteIndex',
  //   component: DoyangCategoryRequisiteIndex
  // },
  {
    path: '/doyang_category_requisites/create',
    name: 'DoyangCategoryRequisiteCreate',
    component: DoyangCategoryRequisiteCreate,
    props: true
  },
  {
    path: '/doyang_category_requisites/:id/edit',
    name: 'DoyangCategoryRequisiteEdit',
    component: DoyangCategoryRequisiteCreate,
    props: true
  },
  //CRUD VENUES
  {
    path: '/venues',
    name: 'VenueIndex',
    component: VenueIndex
  },
  {
    path: '/venues/create',
    name: 'VenueCreate',
    component: VenueCreate
  },
  {
    path: '/venues/:id/edit',
    name: 'VenueEdit',
    component: VenueCreate
  },
  //CRUD FOR PARENT EVENTS
  {
    path: '/parent-events',
    name: 'ParentEventIndex',
    component: ParentEventIndex,
    props: true
  },
  {
    path: '/parent-events/create',
    name: 'ParentEventCreate',
    component: ParentEventCreate,
    props: true
  },
  {
    path: '/parent-events/:id/edit',
    name: 'ParentEventEdit',
    component: ParentEventCreate,
    props: true
  },
  //CRUD FOR STUDENTS
  {
    path: '/students',
    name: 'StudentIndex',
    component: StudentIndex,
    props: true
  },
  {
    path: '/students/create',
    name: 'StudentCreate',
    props: true,
    component: StudentCreate
  },
  {
    path: '/students/:id/edit',
    name: 'StudentEdit',
    component: StudentCreate
  },
  //CRUD FOR SINODALS
    {
      path: '/sinodals',
      name: 'SinodalIndex',
      component: SinodalIndex
    },
    {
      path: '/sinodals/create',
      name: 'SinodalCreate',
      component: SinodalCreate
    },
    {
      path: '/sinodals/:id/edit',
      name: 'SinodalEdit',
      component: SinodalCreate
    },
  //CRUD FOR PROFESSOR
  {
    path: '/professors',
    name: 'ProfessorIndex',
    component: ProfessorIndex
  },
  {
    path: '/professors/create',
    name: 'ProfessorCreate',
    component: ProfessorCreate
  },
  {
    path: '/professors/:id/edit',
    name: 'ProfessorEdit',
    component: ProfessorCreate
  },
  //CRUD FOR EVENTS
  {
    path: '/events/create',
    name: 'EventCreate',
    component: EventCreate
  },
  {
    path: '/events',
    name: 'EventIndex',
    component: EventIndex
  },
  {
    path: '/events/:id/edit',
    name: 'EventEdit',
    component: EventCreate
  },
  {
    path: '/events/:id/prospects',
    name: 'EventProspects',
    component: EventProspects
  },
  //CRUD FOR DEGREE
  {
    path: '/degrees',
    name: 'DegreeIndex',
    component: DegreeIndex
  },
  {
    path: '/degrees/create',
    name: 'DegreeCreate',
    component: DegreeCreate
  },
  {
    path: '/degrees/:id/edit',
    name: 'DegreeEdit',
    component: DegreeCreate
  },
  //CRUD FOR DISCOUNT
  {
    path: '/discounts',
    name: 'DiscountIndex',
    component: DiscountIndex
  },
  {
    path: '/discounts/create',
    name: 'DiscountCreate',
    component: DiscountCreate
  },
  {
    path: '/discounts/:id/edit',
    name: 'DiscountEdit',
    component: DiscountCreate
  },
  //CRUD FOR INVENTORY MOVES
  {
    path: '/inventory_moves',
    name: 'InventoryMoveIndex',
    component: InventoryMoveIndex
  },
  //CRUD FOR OTHER EXAM REQUISITES
  {
    path: '/other_exam_requisites',
    name: 'OtherExamRequisiteIndex',
    component: OtherExamRequisiteIndex
  },
  {
    path: '/other_exam_requisites/create',
    name: 'OtherExamRequisiteCreate',
    component: OtherExamRequisiteCreate
  },
  {
    path: '/other_exam_requisites/:id/edit',
    name: 'OtherExamRequisiteEdit',
    component: OtherExamRequisiteCreate
  },
  //CRUD FOR EXAMS
  {
    path: '/exams',
    name: 'ExamIndex',
    component: ExamIndex
  },
  {
    path: '/exams/create',
    name: 'ExamCreate',
    component: ExamCreate
  },
  {
    path: '/exams/:id/edit',
    name: 'ExamEdit',
    component: ExamCreate
  },
  {
    path: '/exams/:id/prospects',
    name: 'ExamProspects',
    component: ExamProspects
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/monthly_duties',
    name: 'MonthlyDutyIndex',
    component: MonthlyDutyIndex
  },
  {
    path: '/monthly_duties/create',
    name: 'MonthlyDutyCreate',
    component: MonthlyDutyCreate
  },
  {
    path: '/monthly_duties/:id/edit',
    name: 'MonthlyDutyEdit',
    component: MonthlyDutyCreate
  },
  {
    path: '/insurances',
    name: 'InsuranceIndex',
    component: InsuranceIndex
  },
  {
    path: '/general_file_resources',
    name: 'GeneralFileResourceIndex',
    component: GeneralFileResourcesIndex
  },
  {
    path: '/general_file_resources/create',
    name: 'GeneralFileResourceCreate',
    component: GeneralFileResourcesCreate
  },
  {
    path: '/selective_groups',
    name: 'SelectiveGroupIndex',
    component: SelectiveGroupIndex
  },
  {
    path: '/selective_groups/create',
    name: 'SelectiveGroupCreate',
    component: SelectiveGroupCreate
  },
  {
    path: '/selective_groups/:id/edit',
    name: 'SelectiveGroupEdit',
    component: SelectiveGroupCreate
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('Global/loading', true)
  const accessToken = localStorage.getItem('access_token')
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  const isAuthenticated = store.getters['Auth/authInfo']?.user != null
  if (!isAuthenticated) {
    if (to.name != 'Login') {
      router.push({name: 'Login'})
      return
    }
    store.dispatch('Global/loading', false)
    next()
  }
  else
    next()
})

router.afterEach(() => {
    store.dispatch('Global/loading', false)
})

const resetLogin = () => {
    console.log('called');
    localStorage.setItem('access_token', null)
    localStorage.setItem('selectedDoyang', null)
    localStorage.setItem('auth_info', null)
    alert('Tu sesión a caducado, por favor vuelve a iniciar sesión')
    router.push('/login');
}

const debouncedLogin = debounce(resetLogin, 1000)

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 500 && error.response.data.message === "Token has expired") {
        debouncedLogin()
      }
      if (error.response && error.response.status === 401) {
        debouncedLogin()
      }

      return Promise.reject(error);
    }
);

export default router

