<template>
  <div>
    <v-dialog v-model="showPeriodicityOfReportModel" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Seleccione la frecuencia del reporte
        </v-card-title>
        <v-card-text v-if="!isDownloadingReport">
          <v-btn class="blue lighten-2 mr-2" dark @click="downloadGeneralDirectionEventsExcel(false)">Bimestral</v-btn>
          <v-btn class="blue lighten-2 mr-2" dark @click="downloadGeneralDirectionEventsExcel(true)">Anual</v-btn>
        </v-card-text>
        <v-card-text v-else>
          <v-row justify="center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card @click="selectDoyang(doyang)" class="mx-auto" max-width="500px">
      <v-card-title>
        <router-link v-if="isGeneralDirection" :to="{ name: 'DoyangEdit', params: { id: doyang.id } }">
          {{ doyang.name }}
        </router-link>
        <span v-else>{{ doyang.name }}</span>
      </v-card-title>
      <v-card-text style="margin-top: -10px;">
        <span v-if="openingDate">Apertura: {{ openingDate }}</span><span v-if="doyangAge != 'N/A'"> ({{ doyangAge }}
          AÑOS)</span>
        <br>
        <ActiveStudentsDialog :areGoalsModifiable="areGoalsModifiable" :doyang="doyang"></ActiveStudentsDialog>
        <ul>
          <li>
            Asegurados: {{ insuranced_students_count }} (asegurados) / {{ rejected_insurance_students_count
            }} (Rechazaron) / {{ doyang.students_count - insuranced_students_count - rejected_insurance_students_count
            }}
            (Faltan)
          </li>
          <li>
            <Inscriptions :areGoalsModifiable="areGoalsModifiable" :doyang="doyang"></Inscriptions>
          </li>
          <li>
            <Disenrollments :areGoalsModifiable="areGoalsModifiable" :doyang="doyang"></Disenrollments>
          </li>
          <li>
            <DisenrollmentRisk :areGoalsModifiable="areGoalsModifiable" :doyang="doyang"></DisenrollmentRisk>
          </li>
          <li v-if="ENV.DIRECCIÓN_GENERAL_ID == doyang.id">
            <a href="#" @click.prevent="showPeriodicityOfReportModel = true">CSV de eventos</a>
          </li>
        </ul>
        <ul v-if="doyang.next_events_totals">
          <li v-for="eventName in Object.keys(doyang.next_events_totals)" :key="eventName">
            <Events :areGoalsModifiable="areGoalsModifiable" :doyang="doyang" :eventName="eventName"
              :eventId="doyang.next_events_totals[eventName].id"></Events>
          </li>
        </ul>
        <ul v-if="doyang.next_tournaments_totals">
          <li v-for="tournamentName in Object.keys(doyang.next_tournaments_totals)" :key="tournamentName">
            <Tournaments :areGoalsModifiable="areGoalsModifiable" :doyang="doyang" :tournamentName="tournamentName"
              :tournamentId="doyang.next_tournaments_totals[tournamentName].id">
            </Tournaments>
          </li>
        </ul>
        <ul v-if="doyang.next_exams_totals">
          <li v-for="examName in Object.keys(doyang.next_exams_totals) " :key="examName">
            <Exams :areGoalsModifiable="areGoalsModifiable" :doyang="doyang"
              :examId="doyang.next_exams_totals[examName].id" :examName="examName"></Exams>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ENV from '../../../env.js'
import ActiveStudentsDialog from '../home/ActiveStudentsDialog.vue'
import Inscriptions from '../home/Inscriptions.vue'
import Events from '../home/Events.vue'
import Tournaments from '../home/Tournaments.vue'
import Exams from '../home/Exams.vue'
import Disenrollments from '../home/Disenrollments.vue'
import DisenrollmentRisk from '../home/DisenrollmentRisk.vue'

export default {
  name: 'DoyangCard',
  components: {
    ActiveStudentsDialog,
    Inscriptions,
    Events,
    Tournaments,
    Exams,
    Disenrollments,
    DisenrollmentRisk,
  },
  data() {
    return {
      isDownloadingReport: false,
      showPeriodicityOfReportModel: false,
      insuranced_students_count: 0,
      rejected_insurance_students_count: 0,
      ENV
    }
  },
  mounted() {
    // store doyangs show
    this.$store.dispatch('Doyang/show', `${this.doyang.id}?append=insuranced_students_count,rejected_insurance_students_count`).then(res => {
      this.insuranced_students_count = res.insuranced_students_count
      this.rejected_insurance_students_count = res.rejected_insurance_students_count
    })
  },
  props: {
    doyang: {
      type: Object,
      required: true
    },
    areGoalsModifiable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    serverTime() {
      if (!this.$store.getters['Time/time']) {
        return new Date().toISOString()
      }
      return this.$store.getters['Time/time'].split(' ').join('T')
    },
    doyangAge() {
      if (!this.doyang.opening_date) return 'N/A'
      const openingDate = new Date(this.doyang.opening_date)
      const today = new Date(this.serverTime)
      const age = today.getFullYear() - openingDate.getFullYear()
      const m = today.getMonth() - openingDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < openingDate.getDate())) {
        return age - 1
      }
      return age
    },
    authInfo() {
      return this.$store.getters['Auth/authInfo']
    },
    selectedDoyang() {
      return this.$store.getters['Auth/selectedDoyang']
    },
    isGeneralDirection() {
      return this.selectedDoyang?.id == ENV.DIRECCIÓN_GENERAL_ID
    },
    openingDate() {
      if (!this.doyang.opening_date) return 'N/A'
      const yearMonthStringDay = this.doyang.opening_date.split('-')
      let month = new Date(this.doyang.opening_date).toLocaleString('default', { month: 'short' }).toUpperCase();
      if (month == 'JAN') month = 'ENE';
      if (month == 'APR') month = 'ABR';
      if (month == 'AUG') month = 'AGO';
      if (month == 'DEC') month = 'DIC';
      return `${yearMonthStringDay[2].split('T')[0]}/${month}/${yearMonthStringDay[0]}`
    }
  },
  methods: {
    downloadGeneralDirectionEventsExcel(anual = false) {
      this.isDownloadingReport = true
      const stringParams = anual ? '?periodicity=annual' : ''
      this.$store.dispatch("Doyang/downloadFile", `/${this.doyang.id}/event_home_excel${stringParams}`).then((response) => {
        this.downloadExcel(response, 'ReporteGeneralEventosDireccionGeneral.xlsx')
      }).finally(() => {
        this.showPeriodicityOfReportModel = false
        this.isDownloadingReport = false
      })
    },
    downloadExcel(response, fileName) {
      try {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    downloadStringInNewPage(string, filename) {
      const blob = new Blob([string], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    canEditGoals() {
      return this.canSeeGeneralDirection
    },
    canSeeGeneralDirection() {
      if (!this.authInfo) return false
      return ENV.permissions.can_see_general_direction.includes(parseInt(this?.authInfo?.user?.role_id))
    },
    selectDoyang(doyang) {
      this.$store.commit('Auth/selectDoyang', doyang)
      this.$store.dispatch('MonthlyDuty/doyangMonthlyDuties', `?doyang_id=${this.selectedDoyang.id}`).then((res) => {
        this.selectedDoyangMonthlyDuties = res
      })
    },
  },
  selectDoyang(doyang) {
    this.$store.commit('Auth/selectDoyang', doyang)
    this.$store.dispatch('MonthlyDuty/doyangMonthlyDuties', `?doyang_id=${doyang.id}`).then((res) => {
      this.selectedDoyangMonthlyDuties = res
    })
  }
}
</script>

<style scoped>
/* Your style code here */
</style>