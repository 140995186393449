var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[(!_vm.hideSearch)?_c('v-col',{attrs:{"cols":"12"}},[_c('CapsVTextField',{attrs:{"outlined":"","placeholder":_vm.searchPlaceholder},on:{"change":_vm.apiSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),(_vm.hasPermission(_vm.permissionRoot, 'create'))?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","to":{ name: _vm.routeCreateDefault, params: { parentId: _vm.createParentId, onCreatedRedirect: _vm.onCreatedRedirect, ..._vm.extraParams } }}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Añadir nuevo")],1)],1):_vm._e(),_c('v-col',[(_vm.apiPagination)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"server-items-length":_vm.totalItems,"headers":_vm.headersWithActions ? _vm.headersWithActions : [],"items":Array.isArray(_vm.modelIndexArray) ? _vm.modelIndexArray : [],"sort-by":_vm.sortBy.length > 0 ? _vm.sortBy : ['created_at'],"sort-desc":_vm.sortDesc.length > 0 ? _vm.sortDesc : [true],"footer-props":{
        'items-per-page-options': [20],
        'disable-items-per-page': true,
      }},on:{"update:page":(pageNum) => _vm.$emit('pageUpdated', pageNum),"update:sort-by":(column) => _vm.$emit('sortedBy', column),"update:sort-desc":(boolean) => _vm.$emit('sortedDesc', boolean)},scopedSlots:_vm._u([{key:"group.header",fn:function({ group, headers, toggle, isOpen }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(!isOpen)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus")])],1),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(group))])],1)]}},{key:"item.actions",fn:function({ item }){return [(_vm.routeAddPeople)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.goToAddPeople(item)}}},[_vm._v(" mdi-account ")]):_vm._e(),(_vm.hasPermission(_vm.permissionRoot, 'update'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.goToEditItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.showWhatsApp &&
          (item.father_cellphone ||
            item.mother_cellphone ||
            item.cellphone))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openWhatsappDialog(item)}}},[_vm._v(" mdi-whatsapp ")]):_vm._e(),(_vm.hasPermission(_vm.permissionRoot, 'delete') && _vm.isCanDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,false,2320763076)}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"group-by":_vm.groupBy,"headers":_vm.headersWithActions ? _vm.headersWithActions : [],"items":Array.isArray(_vm.modelIndexArray) ? _vm.modelIndexArray : [],"items-per-page":_vm.itemsPerPage,"sort-by":_vm.sortBy.length > 0 ? _vm.sortBy : ['created_at'],"sort-desc":_vm.sortDesc.length > 0 ? _vm.sortDesc : [true],"custom-filter":_vm.filterOnRequiredFields},scopedSlots:_vm._u([{key:"group.header",fn:function({ group, headers, toggle, isOpen }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(!isOpen)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus")])],1),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(group))])],1)]}},{key:"item.actions",fn:function({ item }){return [(_vm.routeAddPeople && _vm.hasPermission(_vm.permissionRouteAddPeople, 'update'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.goToAddPeople(item)}}},[_vm._v(" mdi-account ")]):_vm._e(),(_vm.hasPermission(_vm.permissionRoot, 'update'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.goToEditItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.showWhatsApp && (item.father_cellphone ||
          item.mother_cellphone ||
          item.cellphone))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":() => _vm.parentsWhatsappDialog = true}},[_vm._v(" mdi-whatsapp ")]):_vm._e(),(_vm.hasPermission(_vm.permissionRoot, 'delete') && _vm.isCanDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1),_c('ParentsWhatsappDialog',{attrs:{"self":this.activeSelfWhatsapp,"mother":this.activeMotherWhatsapp,"father":this.activeFatherWhatsapp},model:{value:(_vm.parentsWhatsappDialog),callback:function ($$v) {_vm.parentsWhatsappDialog=$$v},expression:"parentsWhatsappDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }