<template>
  <v-row>
    <v-col cols="12" v-if="!hideSearch">
      <CapsVTextField outlined v-model="search" @change="apiSearch" :placeholder="searchPlaceholder"></CapsVTextField>
    </v-col>
    <v-col cols="12" v-if="hasPermission(permissionRoot, 'create')">
      <v-btn color="primary"
        :to="{ name: routeCreateDefault, params: { parentId: createParentId, onCreatedRedirect, ...extraParams } }"><v-icon>mdi-plus</v-icon>
        Añadir nuevo</v-btn>
    </v-col>
    <v-col>
      <v-data-table :loading="loading" v-if="apiPagination" :server-items-length="totalItems"
        :headers="headersWithActions ? headersWithActions : []"
        :items="Array.isArray(modelIndexArray) ? modelIndexArray : []" class="elevation-1"
        :sort-by="sortBy.length > 0 ? sortBy : ['created_at']" :sort-desc="sortDesc.length > 0 ? sortDesc : [true]"
        @update:page="(pageNum) => $emit('pageUpdated', pageNum)"
        @update:sort-by="(column) => $emit('sortedBy', column)"
        @update:sort-desc="(boolean) => $emit('sortedDesc', boolean)" :footer-props="{
          'items-per-page-options': [20],
          'disable-items-per-page': true,
        }">
        <template v-slot:group.header="{ group, headers, toggle, isOpen }">
          <td :colspan="headers.length">
            <v-btn @click="toggle" x-small icon :ref="group">
              <v-icon v-if="!isOpen">mdi-plus</v-icon>
              <v-icon v-else>mdi-minus</v-icon>
            </v-btn>
            <span class="font-weight-bold">{{ group }}</span>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" v-if="routeAddPeople" @click="goToAddPeople(item)">
            mdi-account
          </v-icon>
          <v-icon small class="mr-2" v-if="hasPermission(permissionRoot, 'update')" @click="goToEditItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon v-if="showWhatsApp &&
            (item.father_cellphone ||
              item.mother_cellphone ||
              item.cellphone)" small class="mr-2" @click="openWhatsappDialog(item)">
            mdi-whatsapp
          </v-icon>
          <v-icon small v-if="hasPermission(permissionRoot, 'delete') && isCanDelete" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-data-table v-else :search="search" :group-by="groupBy" :headers="headersWithActions ? headersWithActions : []"
        :items="Array.isArray(modelIndexArray) ? modelIndexArray : []" :items-per-page="itemsPerPage"
        class="elevation-1" :sort-by="sortBy.length > 0 ? sortBy : ['created_at']"
        :sort-desc="sortDesc.length > 0 ? sortDesc : [true]" :custom-filter="filterOnRequiredFields">
        <template v-slot:group.header="{ group, headers, toggle, isOpen }">
          <td :colspan="headers.length">
            <v-btn @click="toggle" x-small icon :ref="group">
              <v-icon v-if="!isOpen">mdi-plus</v-icon>
              <v-icon v-else>mdi-minus</v-icon>
            </v-btn>
            <span class="font-weight-bold">{{ group }}</span>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" v-if="routeAddPeople && hasPermission(permissionRouteAddPeople, 'update')"
            @click="goToAddPeople(item)">
            mdi-account
          </v-icon>
          <v-icon small class="mr-2" v-if="hasPermission(permissionRoot, 'update')" @click="goToEditItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon v-if="showWhatsApp && (item.father_cellphone ||
            item.mother_cellphone ||
            item.cellphone)" small class="mr-2" @click="() => parentsWhatsappDialog = true">
            mdi-whatsapp
          </v-icon>
          <v-icon small v-if="hasPermission(permissionRoot, 'delete') && isCanDelete" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
    <ParentsWhatsappDialog v-model="parentsWhatsappDialog" :self="this.activeSelfWhatsapp"
      :mother="this.activeMotherWhatsapp" :father="this.activeFatherWhatsapp"></ParentsWhatsappDialog>
  </v-row>
</template>

<script>
var debounce = require('debounce');
import ParentsWhatsappDialog from "../globals/ParentsPhoneDialog.vue"
export default {
  components: {
    ParentsWhatsappDialog
  },
  methods: {
    hasPermission(name, action = false) {
      const names = name.split(",")
      if (names.length > 1) {
        let hasPermission = false
        names.forEach(name => {
          if (hasPermission) return;
          hasPermission = this.hasPermission(name, action)
        })
        return hasPermission
      }
      if (!name) return false;
      const permissions = this.authInfo?.user?.permissions
      if (!permissions) return false
      const singlePermissionkey = Object.keys(permissions).filter(key => {
        return permissions[key].name === name
      })?.[0]
      const singlePermission = permissions?.[singlePermissionkey]
      if (!singlePermissionkey) return false
      if (!action && singlePermission) {
        return (singlePermission?.create || singlePermission?.read || singlePermission?.update || singlePermission?.delete) ? true : false
      }
      return singlePermission?.[action] == 1 || false
    },
    openWhatsappDialog(item) {
      this.parentsWhatsappDialog = true
      this.activeSelfWhatsapp = item.cellphone
      this.activeMotherWhatsapp = item.mother_cellphone
      this.activeFatherWhatsapp = item.father_cellphone
    },
    apiSearch: debounce(function (text) {
      if (this.apiPagination) {
        this.$emit('apiSearch', text)
      }
    }, 800),
    filterOnRequiredFields(value, search, item) {
      const itemSearchField = item.full_name ? item.full_name : item.name
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        itemSearchField.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
    },
    camelToSnakeCase(str) {
      return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    },
    deleteItem(item) {
      item = item.id || item
      const deleteConfirmation = confirm("¿Está seguro que desea eliminar este elemento?")
      if (deleteConfirmation)
        this.$store.dispatch(this.deleteRoute ? this.deleteRoute : `${this.storeModel}/delete`, item)
      else return
    },
    goToAddPeople(item) {
      this.$router.push({ name: this.routeAddPeople, params: { id: item.id } })
    },
    goToEditItem(item) {
      this.$router.push({ name: this.routeEditDefault, params: { id: item.id } })
    }
  },
  mounted() {
    if ((Array.isArray(this.items) && this.items?.length > 0) || !this.runModelRequest) return
    this.$store.dispatch(`${this.storeModel}/index`, this.payload)
  },
  computed: {
    authInfo() {
      return this.$store.getters['Auth/authInfo']
    },
    modelIndexArray: function () {
      if (this.items?.length > 0) return this.items
      if ((this.storeModelPlural || this.storeModel)) {
        const uncappedFirstLetterPlural = this.storeModelPlural.length > 0 ? this.storeModelPlural[0].toLowerCase() + this.storeModelPlural.substring(1) : this.storeModel[0].toLowerCase() + this.storeModel.substring(1) + 's'
        const array = this.$store.getters[`${this.storeModel}/${uncappedFirstLetterPlural}`]
        if (array == "object" && !Array.isArray(array)) {
          return array?.data || []
        }
        return array
      }
      return []
    },
    headersWithActions: function () {
      let headersToReturn = Object.assign(this.headers)
      if (headersToReturn.filter((header) => header.value == "actions").length == 0) {
        headersToReturn.push({ text: "Acciones", value: "actions", sortable: false })
      }
      return headersToReturn
    },
    routeEditDefault: function () {
      return this.routeEdit.length == 0 ? `${this.storeModel}Edit` : this.routeEdit
    },
    routeDeleteDefault: function () {
      return this.routeDelete.length == 0 ? `${this.storeModel}Delete` : this.routeDelete
    },
    routeCreateDefault: function () {
      return this.routeCreate.length == 0 ? `${this.storeModel}Create` : this.routeCreate
    }
  },
  watch: {
    modelIndexArray: function () {
      this.models = this.modelIndexArray?.data || this.modelIndexArray
    }
  },
  data() {
    return {
      parentsWhatsappDialog: false,
      activeSelfWhatsapp: "",
      activeMotherWhatsapp: "",
      activeFatherWhatsapp: "",
      models: [],
      search: '',
      pageNum: 1,
      column: null,
      boolean: false,
    }
  },
  props: {
    isCanDelete: { type: Boolean, default: true },
    permissionRoot: String,
    permissionRouteAddPeople: String,
    runModelRequest: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    apiPagination: { type: Boolean, default: false },
    deleteRoute: String,
    groupBy: Array,
    routeAddPeople: String,
    createParentId: Number,
    totalItems: Number,
    items: Array,
    showWhatsApp: { type: Boolean, default: false },
    headers: Array,
    payload: { type: String, value: '?' },
    storeModel: String,
    storeModelPlural: { type: String, default: '' },
    itemsPerPage: { type: Number, default: 20 },
    routeEdit: { type: String, default: '' },
    routeDelete: { type: String, default: '' },
    routeCreate: { type: String, default: '' },
    onCreatedRedirect: { type: String },
    hideSearch: { type: Boolean, default: false },
    sortBy: { type: Array, default: () => [] },
    sortDesc: { type: Array, default: () => [] },
    searchPlaceholder: { type: String, default: 'Búsqueda' },
    extraParams: { type: Object, default: () => ({}) }
  },
}
</script>

<style></style>
