<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>INVENTARIO</h2>
        <h2>{{ selectedDoyang.name }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="products" :items-per-page="20" show-expand>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table v-for="(variant, index) in item.product_variants" :key="variant.id">
                <template v-slot:default>
                  <thead v-if="index == 0">
                    <th>Existencia</th>
                    <th>Variante</th>
                    <th>Acciones</th>
                    <th>Existencia mínima</th>
                  </thead>
                  <tbody>
                    <tr :class="`${size.existance < size.min_product ? 'red lighten-2' : ''}`"
                      v-for="size in variant.product_sizes" :key="size.id">
                      <td width="33%">{{ size.existance }}</td>
                      <td width="28%">{{ `${variant.name} TALLA: ${size.name}` }}</td>
                      <td width="29%">
                        <InventoryDialog v-if="hasPermission('Entradas/Salidas', 'update')"
                          @accept="editExistanceForDoyang($event, size)" :value="0" title="Editar Existencia"
                          :isLoad="true" :btn_text="'ENTRADA'" :content="'ENTRADA'" />
                        <InventoryDialog v-if="hasPermission('Entradas/Salidas', 'update')"
                          @accept="editExistanceForDoyang($event, size)" :value="0" title="Editar Existencia"
                          :isLoad="false" :btn_text="'SALIDA'" :content="'SALIDA'" />
                      </td>
                      <td width="10%">
                        <div v-if="size.id == editMinProductId">
                          <v-text-field outlined v-model="textEditMinProduct" type="number"></v-text-field>
                          <v-btn class="blue lighten-2" dark @click="editMinProduct(editMinProductId)">OK</v-btn>
                        </div>
                        <span v-else-if="hasPermission('Entradas/Salidas', 'update')">{{ size.min_product ?
                          size.min_product : 0}} <v-icon @click="editMinProductId = size.id">mdi-pencil</v-icon></span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editExistance(item.id)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InventoryDialog from './InventoryDialog.vue'
export default {
  components: {
    InventoryDialog
  },
  mounted() {
    this.$store.dispatch('Product/index',
      `?session_doyang_id=${this.selectedDoyang.id}&with=product_variants,product_variants.product_sizes,product_variants.product_sizes.inventory_moves`)
  },
  methods: {
    hasPermission(name, action = false) {
      const names = name.split(",")
      if (names.length > 1) {
        let hasPermission = false
        names.forEach(name => {
          if (hasPermission) return;
          hasPermission = this.hasPermission(name, action)
        })
        return hasPermission
      }
      if (!name) return false;
      const permissions = this.authInfo?.user?.permissions
      if (!permissions) return false
      console.log('here')
      const singlePermissionkey = Object.keys(permissions).filter(key => {
        return permissions[key].name === name
      })?.[0]
      const singlePermission = permissions?.[singlePermissionkey]
      console.log('singlePermission', singlePermission)
      if (!singlePermissionkey) return false
      if (!action && singlePermission) {
        return (singlePermission?.create || singlePermission?.read || singlePermission?.update || singlePermission?.delete) ? true : false
      }
      return singlePermission?.[action] == 1 || false
    },
    editExistanceForDoyang({ qty, observation, reference }, size) {
      const productSizeId = size.id
      this.$store.dispatch('InventoryMove/create', {
        qty,
        observation,
        reference,
        product_size_id: productSizeId,
        doyang_id: this.selectedDoyang.id
      }).then(() => {
        console.log('then', 'hi')
        this.$store.dispatch('Product/index',
          `?session_doyang_id=${this.selectedDoyang.id}&with=product_variants,product_variants.product_sizes,product_variants.product_sizes.inventory_moves`)
      })
    },
    editExistance(productVariantId) {
      this.$router.push({ name: 'InventoryCreate', params: { id: productVariantId } })
    },
    editMinProduct(productSizeId) {
      this.$store.dispatch('ProductSize/update',
        {
          id: productSizeId,
          doyang_id: this.selectedDoyang.id,
          min_product: this.textEditMinProduct
        }
      ).then((data) => {
        this.products.forEach((product, pIndex) => {
          product.product_variants.forEach((variant, vIndex) => {
            variant.product_sizes.forEach((size, sIndex) => {
              console.log(size.id, data.id)
              if (size.id == data.id) {
                this.$set(this.products[pIndex].product_variants[vIndex].product_sizes[sIndex], 'min_product', this.textEditMinProduct)
              }
            })
          })
        })
        this.editMinProductId = null
      }).catch(() => {
        this.editMinProductId = null
      })
    }
  },
  computed: {
    authInfo() {
      return this.$store.getters['Auth/authInfo']
    },
    products() {
      let products = this.$store.getters['Product/products']
      products = products.map((product) => {
        product.product_variants = product.product_variants.sort((variant1, variant2) => {
          return variant1 > variant2 ? -1 : 1;
        })
        product.product_variants = product.product_variants.map((variant) => {
          variant.product_sizes = variant.product_sizes.sort((size, nextSize) => {
            return size.order > nextSize.order ? 1 : -1
          })
          return variant
        })
        return product
      })
      return products
    },
    selectedDoyang() {
      return this.$store.getters['Auth/selectedDoyang']
    },
  },
  watch: {
    allProducts() {
      this.setProducts()
    },
    inventory() {
      this.setProducts()
    }
  },
  data() {
    return {
      editMinProductId: null,
      textEditMinProduct: null,
      edditingExistenceNum: 0,
      activeExistenceDialog: {
        pivot: {
          quantity: 0
        }
      },
      payload: null,
      headers: [
        { text: 'Producto', value: 'name' },
      ],
      itemsPerPage: 20
    }
  },
}
</script>

<style></style>
