<template>
    <v-container>
        <h2>NUEVO PAGO</h2>
        <v-row>
            <v-col>
                <v-checkbox label="Devolución" v-model="isDevolution"></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="isGeneralDirection || (surrogatedDoyangs && surrogatedDoyangs.length > 0)">
                <v-checkbox label="Venta a doyang" v-model="isSellToDoyang"></v-checkbox>
            </v-col>
            <v-col v-if="isGeneralDirection || selectedDoyang.can_sell_gp_tmp || selectedDoyang.can_sell_gp">
                <v-checkbox label="Venta a público general" :disabled="false"
                    v-model="isSellToGeneralPublic"></v-checkbox>
            </v-col>
        </v-row>
        <v-select label="Concepto" v-model="payment.payment_concept_id" :items="paymentConcepts" item-text="name"
            item-value="id" solo />
        <v-autocomplete v-if="!isSellToDoyang && !isComission && !isSellToGeneralPublic" label="Alumno" chips outlined
            :search-input.sync="searchStudent" :items="students" v-model="payment.user" item-text="full_name"
            return-object :disabled="noteHasClient" />
        <v-autocomplete v-else-if="!isSellToGeneralPublic" label="Doyang" :disabled="noteHasClient"
            v-model="payment.client_doyang" chips outlined :items="surrogatedDoyangs" item-text="name" return-object />
        <v-autocomplete label="Tipo de pago" chips :disabled="noteHasClient" outlined :items="envVars.PAYMENT_TYPES"
            v-model="payment.payment_type" />
        <DMYDateField :label="'Fin del seguro'" v-model="payment.insurance_end"
            v-if="payment.payment_concept_id == envVars.PAYMENT_CONCEPT_INSURANCE_ID" :disabled="true"></DMYDateField>
        <v-checkbox v-if="payment.payment_concept_id == envVars.PAYMENT_CONCEPT_INSURANCE_ID && payment.insurance_end"
            v-model="payment.sell_insurance_next_year" label="Vender para el siguiente año"
            :disabled="!canSellNextYearInsurance"></v-checkbox>
        <caps-v-text-field v-if="!isSellToDoyang && !isComission && isSellToGeneralPublic"
            v-model="payment.gp_client_name" label="Cliente" />
        <v-data-table v-model="selectedComissions" v-if="isComission" select-multiple :items="comissions"
            :sortBy="['parsed_created_at']" :sortDesc="[true]" show-select :headers="[
                { value: 'parsed_created_at', text: 'Fecha' },
                { value: 'observation', text: 'Descripción' },
                { value: 'amount', text: 'Monto' }
            ]" />
        <v-autocomplete label="Producto" v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_PRODUCT_ID && !isComission
            && !sellPackage" solo v-model="product" :items="products" item-text="name" chips return-object />
        <v-autocomplete label="Examen" chips v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EXAM_ID" solo
            v-model="exam" :items="exams" item-text="name" return-object />
        <div>
            <v-btn @click="getNumberOfDebtExams" color="blue lighten-2" dark
                v-if="isSellToDoyang && this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_EXAM_ID">
                Calcular
            </v-btn>
            <v-btn @click="getNumberOfDebtExamsCsv" color="blue lighten-2" dark
                v-if="isSellToDoyang && this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_EXAM_ID">
                Imprimir alumnos
            </v-btn>
        </div>
        <div>
            <v-btn @click="getNumberOfDebtEvents" color="blue lighten-2" dark
                v-if="isSellToDoyang && this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_EVENT_ID">
                Calcular
            </v-btn>
            <v-btn @click="getNumberOfDebtEventsCsv" color="blue lighten-2" dark
                v-if="isSellToDoyang && this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_EVENT_ID">
                Imprimir alumnos
            </v-btn>
        </div>
        <div>
            <v-btn @click="getNumberOfDebtTournaments" color="blue lighten-2" dark
                v-if="isSellToDoyang && this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_TOURNAMENT_ID">
                Calcular
            </v-btn>
            <v-btn @click="getNumberOfDebtTournamentsCsv" color="blue lighten-2" dark
                v-if="isSellToDoyang && this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_TOURNAMENT_ID">
                Imprimir alumnos
            </v-btn>
        </div>
        <caps-v-text-field :label="'Examenes a cobrar'" outlined v-if="debtExams" :disabled="true"
            :value="debtExams"></caps-v-text-field>
        <caps-v-text-field :label="'Eventos a cobrar'" outlined v-if="debtEvents" :disabled="true"
            :value="debtEvents"></caps-v-text-field>
        <caps-v-text-field :label="'Torneos a cobrar'" outlined v-if="debtTournaments" :disabled="true"
            :value="debtTournaments"></caps-v-text-field>

        <v-autocomplete label="Evento" v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EVENT_ID" solo
            v-model="event" :items="events" item-text="name" return-object>
        </v-autocomplete>
        <v-autocomplete label="Torneo" v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_TOURNAMENT_ID" solo
            v-model="tournament" :items="tournaments" item-text="name" return-object>
        </v-autocomplete>
        <v-checkbox
            v-if="isGeneralDirection && payment.payment_concept_id === envVars.PAYMENT_CONCEPT_TOURNAMENT_ID || payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EVENT_ID || payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EXAM_ID"
            :label="`Pagando Comision`" prefix="$" v-model="isPayingComission"></v-checkbox>
        <v-btn v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_TOURNAMENT_ID && isPayingComission"
            class="blue lighten-2" @click="calculateComissions('tournament')" dark>Calcular comisiones</v-btn>
        <v-btn v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EVENT_ID && isPayingComission"
            class="blue lighten-2" @click="calculateComissions('event')" dark>Calcular comisiones</v-btn>
        <v-btn v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EXAM_ID && isPayingComission"
            class="blue lighten-2" @click="calculateComissions('exam')" dark>Calcular comisiones</v-btn>
        <v-row v-if="isPayingComission">
            <v-col>
                Total de alumnos: {{ multiLevelObjectTotal }} | Total de Comisión: ${{ multiLevelObjectTotalAmount ?
                    multiLevelObjectTotalAmount : 'N/A' }}
                <v-data-table :headers="[
                    { text: 'Profesor', value: 'user_name' },
                    { text: 'Doyang', value: 'doyang_name' },
                    { text: 'Multinivel', value: 'surrogation' },
                    { text: 'Alumnos', value: 'students_count' },
                    { text: 'Porcentaje', value: 'forParentText' },
                    { text: 'Comisión', value: 'forParent' }
                ]" :items="multiLevelObject">
                    <template v-slot:item.forParent="{ item }">
                        <span v-if="item.is_kyosa">${{ item.comission }}</span>
                        <span v-else>{{ currencyFormatter.format(item.forParent) }}</span>
                    </template>
                    <template v-slot:item.students_count="{ item }">
                        {{ item.students_count }} ({{ currencyFormatter.format(item.amount) }})
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="!sellPackage">
            <v-col class="pl-10">
                <v-select label="Variante" v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_PRODUCT_ID" solo
                    v-model="productVariant" :items="product.product_variants" item-text="name" return-object>
                </v-select>
                <v-select label="Talla"
                    v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_PRODUCT_ID && productVariant" solo
                    v-model="productSize" :items="productVariant.product_sizes" item-text="name" return-object>
                </v-select>
            </v-col>
        </v-row>
        <caps-v-text-field v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_MONTHLY_PAYMENT && !sellPackage"
            solo label="Cuota Mensual" :disabled="true" prefix="$" v-model="payment.user.doyang.monthly_fee"
            :items="doyangs" item-text="name" return-object></caps-v-text-field>
        <v-checkbox
            v-if="this.payment.user && this.payment.user.has_recommended && payment.payment_concept_id === envVars.PAYMENT_CONCEPT_MONTHLY_PAYMENT && !sellPackage"
            :label="`% por recomentados (${this.payment.user.has_recommended.length * 25}%)`" prefix="$"
            v-model="payment.use_recommended"></v-checkbox>
        <v-checkbox v-if="(payment.payment_concept_id === envVars.PAYMENT_CONCEPT_INSCRIPTION ||
            payment.payment_concept_id === envVars.PAYMENT_CONCEPT_MONTHLY_PAYMENT ||
            payment.payment_concept_id === envVars.PAYMENT_CONCEPT_PRODUCT_ID)
            &&
            (payment.user && payment.user.id)" v-model="sellPackage" label="Vender Paquete"></v-checkbox>
        <v-autocomplete :label="bundles.length == 0 ? 'Cargando paquetes' : 'Paquete'" :disabled="bundles.length == 0"
            v-if="sellPackage" solo v-model="bundle" :items="bundles" item-text="name" return-object>
        </v-autocomplete>
        <v-row v-if="bundle.id">
            <v-col>
                <strong>Producto</strong>
            </v-col>
            <v-col>
                <strong>Cantidad</strong>
            </v-col>
            <v-col>
                <strong>Variante</strong>
            </v-col>
            <v-col>
                <strong>Precio</strong>
            </v-col>
        </v-row>
        <v-row v-if="bundle.covers_monthly_payments_qty > 0">
            <v-col>
                MENSUALIDAD
            </v-col>
            <v-col>
                {{ bundle.covers_monthly_payments_qty }}
            </v-col>
            <v-col></v-col>
            <v-col>
                <p>$ {{ payment.user.doyang.doyang_category.monthly_fee_price }}</p>
            </v-col>
        </v-row>
        <v-row v-for="item in bundle.products" :key="item">
            <v-col>
                {{ item.name }}
            </v-col>
            <v-col>
                {{ item.pivot.qty }}
            </v-col>
            <v-col>
                <v-select @change="addBundleSize" outlined :items="item.product_sizes" item-id="id"
                    item-text="full_name" return-object />
            </v-col>
            <v-col>
                <p>$ {{ bundleProductSizes && bundleProductSizes[item.id] && bundleProductSizes[item.id].price ?
                    bundleProductSizes[item.id].price : "N/A" }}</p>
            </v-col>
        </v-row>
        <v-text-field v-model="payment.quantity"
            v-if="payment.payment_concept_id !== envVars.PAYMENT_CONCEPT_EXAM_ID && !sellPackage && !isComission && payment.payment_concept_id && payment.payment_concept_id !== envVars.PAYMENT_CONCEPT_MONTHLY_PAYMENT"
            :disabled="quantitydisable" solo label="Cantidad" outlined min="0" type="number" />
        <v-row v-if="payment.payment_concept_id &&
            (payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EVENT_ID ||
                payment.payment_concept_id === envVars.PAYMENT_CONCEPT_TOURNAMENT_ID)">
        </v-row>
        <v-row
            v-if="this.tournament && this.tournament.id && payment.payment_concept_id === envVars.PAYMENT_CONCEPT_TOURNAMENT_ID">
            <v-col>
                <v-checkbox
                    :disabled="(this.tournament && !this.tournament.has_combat) || (!this.payment.pays_combat && has2Competitions)"
                    :label="'Combate'" v-model="payment.pays_combat"></v-checkbox>
            </v-col>
            <v-col>
                <v-checkbox
                    :disabled="(this.tournament && !this.tournament.has_forms) || (!this.payment.pays_forms && has2Competitions)"
                    :label="'Forma'" v-model="payment.pays_forms"></v-checkbox>
            </v-col>
            <v-col>
                <v-checkbox
                    :disabled="(this.tournament && !this.tournament.has_boards) || (!this.payment.pays_boards && has2Competitions)"
                    :label="'Rompimientos'" v-model="payment.pays_boards"></v-checkbox>
            </v-col>
            <v-col>
                <v-checkbox
                    :disabled="(this.tournament && !this.tournament.has_kicking_speed) || (!this.payment.pays_kicking_speed && has2Competitions)"
                    :label="'Velocidad de Pateo'" v-model="payment.pays_kicking_speed"></v-checkbox>
            </v-col>
            <v-col>
                <v-checkbox
                    :disabled="(this.tournament && !this.tournament.has_techniques) || (!this.payment.pays_techniques && has2Competitions)"
                    :label="'Ténicas de un paso de combate'" v-model="payment.pays_techniques"></v-checkbox>
            </v-col>
            <v-col>
                <v-checkbox
                    :disabled="(this.tournament && !this.tournament.has_jumping_kicks) || (!this.payment.pays_jumping_kicks && has2Competitions)"
                    :label="'Idan chaguis'" v-model="payment.pays_jumping_kicks"></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox :label="'Parcialidad'" v-model="payment.is_partiality"></v-checkbox>
            </v-col>
            <v-col>
                <v-checkbox :label="'Becado'" v-model="payment.scolarshipped"></v-checkbox>
            </v-col>
            <v-col
                v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EVENT_ID || payment.payment_concept_id === envVars.PAYMENT_CONCEPT_TOURNAMENT_ID || payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EXAM_ID">
                <v-checkbox v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EVENT_ID"
                    :disabled="!this.event.optional_discount_percentage"
                    :label="'Dar Descuento opcional (' + event.optional_discount_percentage + '%)'"
                    v-model="payment.has_optional_discount"></v-checkbox>
                <v-checkbox v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_TOURNAMENT_ID"
                    :disabled="!this.tournament.optional_discount_percentage"
                    :label="'Dar Descuento opcional (' + tournament.optional_discount_percentage + '%)'"
                    v-model="payment.has_optional_discount"></v-checkbox>
                <v-checkbox v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EXAM_ID"
                    :disabled="!this.exam.optional_discount_percentage"
                    :label="'Dar Descuento opcional (' + exam.optional_discount_percentage + '%)'"
                    v-model="payment.has_optional_discount"></v-checkbox>
            </v-col>
        </v-row>
        <v-row v-if="payment.payment_concept_id &&
            (payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EVENT_ID ||
                payment.payment_concept_id === envVars.PAYMENT_CONCEPT_TOURNAMENT_ID)">
            <v-col>
                <h4>PAQUETES DE TORNEO</h4>
                <v-col cols=12>
                    <v-col cols=12>
                        <v-row v-if="payment.payment_concept_id &&
                            (payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EVENT_ID ||
                                payment.payment_concept_id === envVars.PAYMENT_CONCEPT_TOURNAMENT_ID)">
                            <v-col cols=7>
                                <CapsVTextField :label="`Nombre`" v-model="companionName"></CapsVTextField>
                            </v-col>
                            <v-col cols=3>
                                <CapsVTextField type="number" :label="'Edad'" v-model="companionAge"></CapsVTextField>
                            </v-col>
                            <v-col cols=2>
                                <v-btn @click="addCompanion" color="blue lighten-2">Añadir paquete</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-col>
                <br>
                <ul>
                    <li v-for="(companion, index) in payment.companionList" :key="companion.name">
                        {{ companion.name }} ({{ companion.age }} años) <v-btn @click="deleteCompanion(index)"
                            color="red lighten-2">Quitar</v-btn>
                    </li>
                </ul>
            </v-col>
        </v-row>
        <v-row v-if="payment.payment_concept_id &&
            (payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EVENT_ID ||
                payment.payment_concept_id === envVars.PAYMENT_CONCEPT_TOURNAMENT_ID)">
            <v-col cols=12>
                <h3>EXTRAS</h3>
            </v-col>
            <v-col cols="4">
                <v-checkbox v-model="colaborate_mom" label="Asiste Mamá" color="green lighten-2"
                    hide-details></v-checkbox>
            </v-col>
            <v-col cols="4">
                <v-checkbox v-model="colaborate_dad" label="Asiste Papá" color="green lighten-2"
                    hide-details></v-checkbox>
            </v-col>
            <v-col cols="4">
                <v-checkbox v-model="colaborates" label="Colabora" color="green lighten-2"></v-checkbox>
            </v-col>
        </v-row>
        <v-row v-if="payment.payment_concept_id === envVars.PAYMENT_CONCEPT_EXAM_ID">
            <v-col cols=6>
                <v-checkbox :disabled="true" v-model="payment.prepay_discount_meeting" value
                    label="Descuento por junta"></v-checkbox>
            </v-col>
            <v-col cols=6>
                <v-checkbox :disabled="true || !payment.prepay_discount_revision"
                    v-model="payment.prepay_discount_revision" value label="Descuento por requisitos"></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="!isComission">
                <v-btn :disabled="btndisable" color="blue lighten-2" class="white--text" @click="addToNote()">
                    Añadir a la nota
                </v-btn>
            </v-col>
            <v-col v-else>
                <v-btn :disabled="false" color="blue lighten-2" class="white--text" @click="payComissions()">
                    Pagar
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h4>NOTA DE VENTA</h4>
            </v-col>
        </v-row>
        <v-row v-if="lastReceiptId">
            <v-col>
                Sin formato<v-icon @click="printLastReceipt(true)">mdi-printer</v-icon>
            </v-col>
            <v-col>
                Con formato <v-icon @click="printLastReceipt(false)">mdi-printer</v-icon>
            </v-col>
            <v-col v-if="lastReceipt && lastReceipt.client_phone">
                Whatsapp por defecto <v-icon
                    @click="sendWhatsappMessage(lastReceipt.client_phone)">mdi-whatsapp</v-icon>
            </v-col>
            <v-col>
                Otro <v-icon @click="sendWhatsappMessage()">mdi-whatsapp</v-icon>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table :items="noteItems" :headers="headers" :single-expand="true" :expanded.sync="expanded"
                    show-expand>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>Vender con múltiples métodos de págo</v-col>
            <v-col>
                <v-icon v-if="showMultiplePaymentMethods"
                    @click="showMultiplePaymentMethods = false">mdi-chevron-up</v-icon>
                <v-icon v-else @click="showMultiplePaymentMethods = true">mdi-chevron-down</v-icon>
            </v-col>
        </v-row>
        <v-row v-if="showMultiplePaymentMethods">
            <v-col cols=12>
                ${{ totalSumOfPrice }}
            </v-col>
            <v-col md="3">
                <CapsVTextField outlined type="number" v-model="credit_total" label="Pago en tarjeta de crédito"
                    prefix="$" />
            </v-col>
            <v-col md="3">
                <CapsVTextField outlined type="number" v-model="debit_total" label="Pago en tarjeta de débito"
                    prefix="$" />
            </v-col>
            <v-col md="3">
                <CapsVTextField outlined v-model="cash_total" label="Pago en efectivo" prefix="$" />
            </v-col>
            <v-col md="3">
                <CapsVTextField outlined type="number" v-model="deposit_total" label="Depósito" prefix="$" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn :disabled="sellLoading || this.noteItems.length < 1" color="green lighten-2" class="white--text"
                    @click="sell()">
                    Vender
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn :disabled="!this.resetSaleButton" color="green lighten-2" class="white--text"
                    @click="resetSale()">
                    Reiniciar Venta
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
var debounce = require('debounce');

import ENV from '../../../env'
import CapsVTextField from '../globals/CapsVTextField.vue'
export default {
    components: { CapsVTextField },
    mounted() {
        this.isSellToDoyang = this.isGeneralDirection
        this.$store.dispatch('Time/time')
        this.authInfo = this.$store.getters['Auth/authInfo']
        this.$store.dispatch('Configuration/show', 7).then((res) => {
            this.insurancePrice = res.value
        }) //Insurance price
        this.$store.dispatch('Configuration/show', ENV.PAYMENT_CONCEPT_INSURANCE_ID) //Insurance
        this.$store.dispatch('Configuration/show', 13).then((res) => {
            this.payment['insurance_end'] = res.value
            this.paymentTemplate['insurance_end'] = res.value
        })
        this.$store.dispatch('Configuration/show', 41).then((res) => {
            this.canSellNextYearInsurance = res.value != 0
        })
        if (!localStorage.getItem('selectedDoyang') && !(this.authInfo && this.authInfo.user && ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
            this.$router.push({ name: 'Home' })
        }
        if (this.selectedDoyang.id == ENV.DIRECCIÓN_GENERAL_ID) {
            this.$store.dispatch('Doyang/index', 'with=doyang_surrogation&append=inscription_fee&active=1')
        }
        this.$store.dispatch('Doyang/show', this.selectedDoyang.id + '?with=doyang_category.bundles,doyang_category.bundles.products,doyang_category.bundles.products.product_variants,doyang_category.bundles.products.product_sizes')
        // if (this.isGeneralDirection) {
        //     this.$store.dispatch('Student/index','?with=doyang&active=1')
        // } else {
        //     this.$store.dispatch('Student/index',`?doyang_id=${this.$store.getters['Auth/selectedDoyang'].id}&with=doyang`)
        // }
        this.$store.dispatch('PaymentConcept/index')
        // this.$store.dispatch('Exam/index', '?active=1')
        this.$store.dispatch('Exam/index', '')
        this.$store.dispatch('Tournament/index', '?active=1')
        this.$store.dispatch('Event/index', '?active=1')
        this.$store.dispatch('Product/index', '?with=product_variants,product_variants.product_sizes,multilevel_discount_type')
        this.envVars = ENV
    },
    methods: {
        loadComissions() {
            this.isLoadingComissions = true
            const client_doyang_id = this.payment.client_doyang.id
            this.$store.dispatch('Payment/index', `?orderBy[created_at]=desc&doyang_id=${client_doyang_id}&paginate=15&is_paying_doyang_comissions=1`).then(() => {
                this.isLoadingComissions = false
            }).catch(() => {
                this.isLoadingComissions = false
            })
        },
        filterPassed(arrayToFilter) {
            return arrayToFilter.filter((item) => {
                return item.date.substring(0, 10) >= this.serverTime.substring(0, 10)
            })
        },
        filterPassedAndActive(arrayToFilter) {
            return arrayToFilter.filter((item) => {
                return (item.payment_student_due_date?.substring(0, 10) >= this.serverTime.substring(0, 10)) && item.active
            })
        },
        setExamDiscounts(newExam = null) {
            this.$store.dispatch('Time/time').then((res) => {
                const serverDate = res.substring(0, 10);
                newExam = newExam || this.exam
                if (!(newExam?.id &&
                    newExam?.early_payment_student_due_date &&
                    serverDate <= (newExam?.early_payment_student_due_date?.substring(0, 10) || '') &&
                    this.payment.user)) return
                this.$store.dispatch('Student/show', `${this.payment.user.id}?exam_id=${newExam.id}&append=exam_discounts`).then(res => {
                    this.isRevisionApproved = res?.exam_discounts?.revision;
                    this.payment.prepay_discount_meeting = false;
                    this.payment.prepay_discount_revision = false;
                    if (serverDate <= (newExam?.early_payment_student_due_date?.substring(0, 10) || '')) {
                        this.payment.prepay_discount_meeting = res?.exam_discounts?.meeting;
                        if (this.payment.prepay_discount_meeting) {
                            this.payment.prepay_discount_revision = res?.exam_discounts?.isDiscount;
                        }
                    }
                })
            });
        },
        resetSale() {
            this.noteItems = []
            this.lastReceiptId = null
            const prevPayment = JSON.parse(JSON.stringify(this.payment))
            const prevPaymentType = prevPayment.payment_type
            const prevUser = prevPayment.user
            const prevDoyang = prevPayment.client_doyang
            const prevDoyangId = prevPayment.client_doyang_id
            this.noteHasClient = false
            this.payment.monthsAdded = 0
            this.$set(this, 'payment', JSON.parse(JSON.stringify(this.paymentTemplate)))
            this.$set(this.payment, 'user', prevUser)
            this.$set(this.payment, 'doyang_id', prevDoyangId)
            this.$set(this.payment, 'client_doyang', prevDoyang)
            this.$set(this.payment, 'payment_type', prevPaymentType)
        },
        calculateComissions(type = 'tournament') {
            const userId = this.payment.user.id
            let eventId = null
            switch (this.payment.payment_concept_id) {
                case this.envVars.PAYMENT_CONCEPT_EXAM_ID:
                    eventId = this.payment.exam_id
                    break;
                case this.envVars.PAYMENT_CONCEPT_TOURNAMENT_ID:
                    eventId = this.payment.tournament_id
                    break;
                case this.envVars.PAYMENT_CONCEPT_EVENT_ID:
                    eventId = this.payment.event_id
                    break;
                default:
                    break;
            }
            this.$store.dispatch('Student/show', `${userId}?append=multilevel_students_event&${type}_id=${eventId}`).then(res => {
                this.multiLevelObject = res.multilevel_students_event
                this.multiLevelObjectTotal = res.multilevel_students_event.reduce((accum, element) => {
                    return accum + element.students_count
                }, 0)
                this.multiLevelObjectTotalAmount = res.multilevel_students_event.reduce((accum, element) => {
                    return (+accum) + (+element.forParent)
                }, 0)
            })
        },
        deleteCompanion(index) {
            this.payment.companionList.splice(index, 1)
        },
        addCompanion() {
            this.payment.companionList.push({
                name: this.companionName,
                age: this.companionAge,
            })
        },
        getNumberOfDebtExams() {
            this.$store.dispatch('Exam/show', `${this.exam.id}?doyang_id=${this.payment.client_doyang.id}&append=total_paid_count`).then(res => {
                this.debtExams = res.total_paid_count
            });
        },
        getNumberOfDebtExamsCsv() {
            this.$store.dispatch('Exam/show', `${this.exam.id}?doyang_id=${this.payment.client_doyang.id}&append=total_paid_count_csv`).then(res => {
                this.downloadStringInBlankPage(res.total_paid_count_csv, 'number_of_debt_exams.csv')
            });
        },
        getNumberOfDebtEvents() {
            this.$store.dispatch('Event/show', `${this.event.id}?doyang_id=${this.payment.client_doyang.id}&append=total_paid_count`).then(res => {
                this.debtEvents = res.total_paid_count
            });
        },
        getNumberOfDebtEventsCsv() {
            this.$store.dispatch('Event/show', `${this.event.id}?doyang_id=${this.payment.client_doyang.id}&append=total_paid_count_csv`).then(res => {
                this.downloadStringInBlankPage(res.total_paid_count_csv, 'number_of_debt_events.csv')
            });
        },
        getNumberOfDebtTournaments() {
            this.$store.dispatch('Tournament/show', `${this.tournament.id}?doyang_id=${this.payment.client_doyang.id}&append=total_paid_count`).then(res => {
                this.debtTournaments = res.total_paid_count
            });
        },
        getNumberOfDebtTournamentsCsv() {
            this.$store.dispatch('Tournament/show', `${this.tournament.id}?doyang_id=${this.payment.client_doyang.id}&append=total_paid_count_csv`).then(res => {
                this.downloadStringInBlankPage(res.total_paid_count_csv, 'number_of_debt_tournaments.csv')
            });
        },
        downloadStringInBlankPage(string, filename = 'assistances.csv') {
            const bom = '\uFEFF';  // UTF-8 BOM
            const blob = new Blob([bom + string], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        addBundleSize(item) {
            if (item) {
                this.$set(this.bundleProductSizes, item.product_id, item)
            }
        },
        sendWhatsappMessage(phone) {
            phone = phone || prompt('Teléfono a 10 dígitos')
            window.open(`https://wa.me/+52${phone}`, '_blank')
        },
        printLastReceipt(noFormat) {
            this.$store.dispatch(`Receipt/print`, `${this.lastReceiptId}?print=1&no_format=${noFormat || 0}`)
        },
        deleteItem(item) {
            this.noteItems = this.noteItems.filter(noteItem => noteItem.identifier != item.identifier)
        },
        sell() {
            this.sellLoading = true;
            const totalSum = ((+this.credit_total) + (+this.debit_total) + (+this.cash_total) + (+this.deposit_total));
            if ((totalSum || 0) != (this.totalSumOfPrice || 0)) {
                alert('La suma de los pagos no coincide con el total')
                this.sellLoading = false
                return
            }
            this.$store.dispatch('Payment/create', {
                noteItems: this.noteItems || 0,
                credit_total: this.credit_total || 0,
                debit_total: this.debit_total || 0,
                cash_total: this.cash_total || 0,
                deposit_total: this.deposit_total || 0,
            }).then((res) => {
                alert('Venta realizada con éxito')
                if (Array.isArray(res?.warnings)) {
                    res.warnings.forEach((warning) => {
                        alert(warning)
                    })
                }
                this.lastReceipt = res
                this.lastReceiptId = res.id
                this.noteItems = []
                this.noteHasClient = false
                this.payment.monthsAdded = 0
                this.isSellToGeneralPublic = false
                this.sellLoading = false
            }).catch((e) => {
                let msg = e.response.data.msg ? e.response.data.msg : e.response.data
                if (msg?.message?.includes('Para vender')) {
                    alert('Error en al venta: ' + msg.message)
                    return
                }
                if (typeof (msg) == "object")
                    msg = JSON.stringify(msg)
                alert('Error en al venta: ' + msg)
                this.resetSaleButton = true
                this.sellLoading = false
            })
        },
        payComissions() {
            const sendComission = JSON.parse(JSON.stringify(this.selectedComissions.map((comission) => {
                comission['paid_date'] = new Date(Date.now()).toISOString().substring(0, 10)
                return comission
            })))
            this.$store.dispatch('Payment/update', { id: 0, doyang_id: this.selectedDoyang.id, models: sendComission }).then(() => {
                this.selectedComissions = []
            })
        },
        addToNote() {
            if (this.payment.payment_type == 'FAVOR DE DEFINIR') {
                alert('Selecciona un tipo de pago')
                return
            }
            //validate we are in the doyang
            if (!this.$store.getters['Auth/selectedDoyang'] && this.$store.getters['Auth/selectedDoyang'].id) {
                alert('Error, intenta refrescar la página')
                return
            }
            let discount = 0
            let client_doyang_id = null
            let discountReason = ""
            // Set discount
            if (this.isSellToDoyang) {
                const client_doyang = this.payment.client_doyang ? this.payment.client_doyang : null
                client_doyang_id = client_doyang?.id || null
                if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_PRODUCT_ID) {
                    const multilevel_discount_type_id = this.product?.multilevel_discount_type_id || 0
                    const discountType = client_doyang?.doyang_surrogation?.parsed_multilevel_discount_types?.filter(discountType => {
                        return multilevel_discount_type_id == discountType.id
                    })?.[0] || null
                    discount = (discountType?.percentage || 0) / 100
                    discountReason = `${discountType?.percentage}% ${client_doyang?.doyang_surrogation?.name}`
                }
            }
            let discountText = `$${discount * this.payment.price * this.payment.quantity}`
            let total = this.payment.price != 0 ? "$ " + ((this.payment.price * this.payment.quantity) * (1 - discount)) : 'Gratis';
            let price = this.payment.price;
            //Selling a bundle
            if (this.sellPackage) {
                total = `${total}`
                price = `${price}`
                let qty_covered_months = this.bundle?.covers_monthly_payments_qty
                if (qty_covered_months > 0) {
                    // alert('Para vender este paquete necesitas estar al corriente con tus pagos.')
                    // return
                    let first_uncovered_month = this.payment.user.first_uncovered_month
                    const arrayUncoveredDate = first_uncovered_month.split("-")
                    let monthToCover = new Date(Date.UTC(arrayUncoveredDate[0], arrayUncoveredDate[1], arrayUncoveredDate[2]))
                    monthToCover.setDate(1)
                    let monthToCoverBeginning = new Date(Date.UTC(arrayUncoveredDate[0], arrayUncoveredDate[1], arrayUncoveredDate[2]))
                    const monthToSet = monthToCover.getMonth() + (qty_covered_months - 1)
                    monthToCover.setMonth(monthToSet)
                    let monthNormalNumberBeginning = +(monthToCoverBeginning.getMonth() + 1) + this.payment.monthsAdded;
                    monthNormalNumberBeginning = monthNormalNumberBeginning > 12 ? monthNormalNumberBeginning % 12 : monthNormalNumberBeginning
                    let monthNormalNumber = (+monthToCover.getMonth() + 1) + this.payment.monthsAdded;
                    this.payment.monthsAdded = this.payment.monthsAdded + 1
                    monthNormalNumber = monthNormalNumber > 12 ? monthNormalNumber % 12 : monthNormalNumber
                    const monthToCoverString = ENV.months[monthNormalNumber]['abv'].toUpperCase()
                    let monthToCoverBeginningString = ENV.months[monthNormalNumberBeginning]['abv'].toUpperCase()
                    monthToCoverBeginningString = (monthToCoverBeginningString == monthToCoverString) ? '' : monthToCoverBeginningString + '-'
                    this.payment.name = `${this.payment.name} ${monthToCoverBeginningString}${monthToCoverString}`
                }
            }
            else if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_INSURANCE_ID) {
                this.payment.name = 'Seguro'
                this.payment.price = JSON.parse(JSON.stringify(parseInt(this.insurancePrice)))
                this.payment.total = JSON.parse(JSON.stringify(parseInt(this.insurancePrice)))
                total = JSON.parse(JSON.stringify(parseInt(this.insurancePrice)))
            }
            //Selling exam count to doyang
            else if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_EXAM_ID && this.isSellToDoyang) {
                client_doyang_id = this.payment.client_doyang?.id || null
                discount = 0
                discount += this.exam.prepay_discount_meeting
                discount += this.exam.prepay_discount_revision
                const unitPrice = this.exam.price - discount;
                price = unitPrice;
                this.payment.quantity = this.debtExams
                total = `$${(unitPrice * this.debtExams)}`
                this.payment.name = this.exam.name
            }
            //Selling event count to doyang
            else if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_EVENT_ID && this.isSellToDoyang) {
                client_doyang_id = this.payment.client_doyang?.id || null
                discount = 0
                const unitPrice = this.event.price;
                price = unitPrice;
                this.payment.quantity = this.debtEvents
                total = `$${(unitPrice * this.debtEvents)}`
                this.payment.name = this.event.name
            }
            //Selling tournament count to doyang
            else if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_TOURNAMENT_ID && this.isSellToDoyang) {
                client_doyang_id = this.payment.client_doyang?.id || null
                discount = 0
                const unitPrice = this.tournament.price;
                price = unitPrice;
                this.payment.quantity = this.debtExams
                total = `$${(unitPrice * this.debtTournaments)}`
                this.payment.name = this.tournament.name
            }
            else if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_EXAM_ID) {
                const todayMonthDate = this.serverTime.substring(0, 7)
                if ((this.payment.user.first_uncovered_month).substring(0, 7) <= todayMonthDate) {
                    alert('Para vender este examen el alumno necesita estar al corriente con tus pagos.')
                    return
                }
                discount = 0
                discount += Number(this.payment.prepay_discount_meeting ? this.exam.prepay_discount_meeting || 0 : 0)
                discount += Number(this.payment.prepay_discount_revision ? this.exam.prepay_discount_revision || 0 : 0)
                discountText = `$${discount}`;
                total = this.payment.total - discount;
                price = this.payment.total;
                this.payment.name = this.exam.name
            }
            else if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_TOURNAMENT_ID) {
                discount = 0
                const serverTimeDay = this.serverTime.substring(0, 10)
                const earlyPaymentDay = this.tournament.early_payment_student_due_date.substring(0, 10)
                if (!this.payment.has_optional_discount && serverTimeDay <= earlyPaymentDay) {
                    discount += Number(this.serverTime < this.tournament.early_payment_amount ? (this.tournament.price - this.tournament.early_payment_amount) : 0)
                }
                if (this.payment.has_optional_discount) {
                    discount += Number(this.tournament.optional_discount_percentage ? (this.tournament.price * this.tournament.optional_discount_percentage / 100) : 0)
                }
                discountText = `$${discount}`;
                let price = 0;
                if (!this.payment.has_optional_discount && (serverTimeDay <= earlyPaymentDay) && (this.tournament.early_payment_amount)) {
                    if (this.numberOfModalities == 2)
                        price = this.tournament.early_payment_amount_two_modalities;
                    else if (this.numberOfModalities == 1)
                        price = this.tournament.early_payment_amount
                } else {
                    if (this.numberOfModalities == 2)
                        price = this.tournament.price_amount_two_modalities;
                    else if (this.numberOfModalities == 1)
                        price = this.tournament.price
                }
                const each_comapnion_amount = (serverTimeDay <= earlyPaymentDay) ? (Number(this.tournament?.early_payment_amount_companion) || 0) : (Number(this.tournament?.price_companion) || 0);
                let partialities = 1
                if (this.payment.is_partiality) {
                    partialities = (this.payment.is_partiality ? (this.tournament.number_of_patialities || 1) : 1)

                }
                total = (price - discount + (each_comapnion_amount * this.payment.companionList?.length)) / partialities;
                const forms = this.payment.pays_forms ? 'FORMA; ' : ' ';
                const combat = this.payment.pays_combat ? 'COMBATE; ' : ' ';
                const techniques = this.payment.pays_techniques ? 'TÉCNICAS; ' : ' ';
                const jumping_kicks = this.payment.pays_jumping_kicks ? 'IDAN CHAGUIS; ' : ' ';
                const boards = this.payment.pays_boards ? 'ROMPIMIENTOS; ' : ' ';
                const kicking_speed = this.payment.pays_kicking_speed ? 'VELOCIDAD DE PATEO; ' : ' ';
                const competitionString = `(${forms}${combat}${techniques}${jumping_kicks}${boards}${kicking_speed})`
                const partialityString = `${this.payment.is_partiality ? "(PARCIALIDAD)" : ""}`
                this.payment.name = this.tournament.name + `${this.payment.companionList?.length ? ' (' + this.payment.companionList?.length + ' PAQUETES)' : ''}`
                this.payment.name = this.payment.name + competitionString + partialityString
            }
            else if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_EVENT_ID) {
                discount = 0
                if (this.event.early_payment_amount) {
                    discount += Number(this.serverTime < this.event.early_payment_amount ? (this.event.price - this.event.early_payment_amount) : 0)
                }
                discountText = `$${discount}`;
                let price = 0;
                if (!this.event.early_payment_student_due_date || this.serverTime < this.event.early_payment_student_due_date && this.event.early_payment_amount) {
                    price = this.event.price;
                } else {
                    price = this.event.early_payment_amount;
                }
                const each_comapnion_amount = (this.serverTime < this.event.early_payment_student_due_date) ? (Number(this.event?.early_payment_amount_companion) || 0) : (Number(this.event?.price_companion) || 0);
                let partialities = 1
                partialities = (this.payment.is_partiality ? (this.event.number_of_patialities || 1) : 1)

                total = (price - discount + (each_comapnion_amount * this.payment.companionList?.length)) / partialities;
                const partialityString = `${this.payment.is_partiality ? "(PARCIALIDAD)" : ""}`
                this.payment.name = this.event.name + `${this.payment.companionList?.length ? ' (' + this.payment.companionList?.length + ' PAQUETES)' : ''}`
                this.payment.name = this.payment.name + partialityString

            }
            else if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_MONTHLY_PAYMENT && this.payment.user.doyang.monthly_fee) {
                this.payment.quantity = 1;
                this.payment.price = this.payment.user.doyang.monthly_fee;
                price = this.payment.price;

                let first_uncovered_month = this.payment.user.first_uncovered_month;
                const arrayUncoveredDate = first_uncovered_month.split("-");
                let year = +arrayUncoveredDate[0]; // Get the year
                let month = +arrayUncoveredDate[1] - 1; // Convert month to zero-indexed (January is 0)

                // Create a date object from the first uncovered month
                let uncoveredDate = new Date(year, month);

                // Add the months from this.payment.monthsAdded
                uncoveredDate.setMonth(uncoveredDate.getMonth() + this.payment.monthsAdded);

                // Now `uncoveredDate` is the updated date with proper month/year rollover handling
                let updatedYear = uncoveredDate.getFullYear();
                let updatedMonth = uncoveredDate.getMonth() + 1; // Convert back to 1-indexed

                // Get the server date year and month
                let serverDate = new Date(this.serverTime.substring(0, 10));
                let serverYear = serverDate.getFullYear();
                let serverMonth = serverDate.getMonth() + 1; // Convert back to 1-indexed

                // Now compare both year and month
                if ((updatedYear > serverYear || (updatedYear === serverYear && updatedMonth > serverMonth))
                    && this.payment.use_recommended) {
                    discount = ((this.payment.user.has_recommended.length || 0) * 0.25) * price;
                }
                this.payment.monthsAdded = this.payment.monthsAdded + 1
                const stringMonths = ENV.months[updatedMonth];
                discountText = this.payment.use_recommended ? `$${discount} (Recomendados)` : '';
                total = this.payment.price - discount
                this.payment.name = `Mensualidad (${stringMonths['abv'].toUpperCase()})`
            }
            const user = !this.isSellToDoyang && this.payment?.user ? this.payment.user : null
            // Before adding actions
            total = (this.payment.scolarshipped) ? 'Becado' : total

            this.noteItems.push({
                has_optional_discount: this.payment.has_optional_discount,
                sell_insurance_next_year: this.canSellNextYearInsurance,
                colaborate_mom: this.colaborate_mom,
                colaborate_dad: this.colaborate_dad,
                colaborates: this.colaborates,
                isSellToGeneralPublic: this.isSellToGeneralPublic,
                pay_forms: this.payment.pays_forms,
                pay_combat: this.payment.pays_combat,
                pay_boards: this.payment.pays_boards,
                pay_kicking_speed: this.payment.pays_kicking_speed,
                pay_techniques: this.payment.pays_techniques,
                pay_jumping_kicks: this.payment.pays_jumping_kicks,
                elaborate_user: this.authInfo.user,
                json_companions: this.payment.companionList,
                doyangBundle: this.bundle,
                bundleProductSizes: this.bundleProductSizes,
                discount_reason: discountReason || "",
                gp_client_name: this.payment.gp_client_name,
                identifier: this.noteItemsCounter,
                doyang_id: this.$store.getters['Auth/selectedDoyang'].id,
                product: `${this.isDevolution ? 'DEVOLUCIÓN: ' : ''}${this.productSize?.full_name || this.payment.name}`,
                exam: this.exam,
                event: this.event,
                tournament: this.tournament,
                full_name: this.isSellToDoyang ? this.payment.client_doyang.name : this.payment.user.full_name,
                user,
                priceText: this.payment.price != 0 ? "$ " + this.payment.price : 'Gratis',
                price,
                total,
                quantity: this.payment.quantity,
                payment_concept_id: this.payment.payment_concept_id,
                payment_type: this.payment.payment_type,
                product_size: this.productSize,
                devolution: this.isDevolution,
                discount: discountText,
                client_doyang_id,
                prepay_discount_meeting: this.payment.prepay_discount_meeting,
                prepay_discount_revision: this.payment.prepay_discount_revision,
                is_partiality: this.payment.is_partiality,
                insurance_end: this.payment?.insurance_end,
                use_recommended: this.payment?.use_recommended,
                scolarshipped: this.payment?.scolarshipped,
            })
            this.noteHasClient = true
            this.noteItemsCounter++
            this.productVariant = null
            this.productSize = null
            const prevPayment = JSON.parse(JSON.stringify(this.payment))
            const prevPaymentType = prevPayment.payment_type
            const prevUser = prevPayment.user
            const prevDoyang = prevPayment.client_doyang
            const prevDoyangId = prevPayment.client_doyang_id
            const monthsAdded = prevPayment.monthsAdded
            this.$set(this, 'payment', JSON.parse(JSON.stringify(this.paymentTemplate)))
            this.$set(this.payment, 'user', prevUser)
            this.$set(this.payment, 'doyang_id', prevDoyangId)
            this.$set(this.payment, 'client_doyang', prevDoyang)
            this.$set(this.payment, 'payment_type', prevPaymentType)
            this.$set(this.payment, 'monthsAdded', monthsAdded)
        }
    },
    watch: {
        'payment.insurance_end': function () {
            if (!this.payment?.user) return
            this.btndisable = false
        },
        'payment.user': function () {
            this.setExamDiscounts()
            this.companionName = this.payment.user?.full_name
            this.companionAge = this.payment.user?.age
            if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_MONTHLY_PAYMENT && this.payment.user.doyang.monthly_fee) {
                this.btndisable = false
            }
            if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_INSURANCE_ID && this.payment?.user) {
                this.btndisable = false
            }
            if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_INSCRIPTION) {
                if (this.payment?.user?.doyang?.inscription_fee) {
                    this.$set(this.payment, 'price', this.payment.user.doyang.inscription_fee)
                    this.btndisable = false
                }
            }
        },
        'searchStudent': debounce(function () {
            let activeString = `active=${this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_INSCRIPTION ? 0 : 1}`
            if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_BUNDLE) {
                activeString = ''
            }
            if (this.isGeneralDirection) {
                this.$store.dispatch('Student/index', `?append=first_uncovered_month,age&with=has_recommended,doyang,doyang.doyang_category,doyang.doyang_category.bundles&name=${this.searchStudent}&${activeString}`)
            } else {
                this.$store.dispatch('Student/index', `?append=first_uncovered_month,age&with=has_recommended,doyang,doyang.doyang_category,doyang.doyang_category.bundles&doyang_id=${this.selectedDoyang.id}&name=${this.searchStudent}&${activeString}`)
            }
        }, 1000),
        'selectedComissions': function () {
            const total = this.selectedComissions.reduce((accum, payment) => accum + payment.amount, 0)
            this.payment.total = total
        },
        'isComission': function () {
            if (this.isComission && this.payment?.client_doyang) {
                this.$store.dispatch('Payment/index', `?doyang_id=${this.payment.client_doyang.id}&is_paying_doyang_comissions=1&paginate=15&orderBy[created_at]=desc`)
            }
        },
        'payment.payment_concept_id': function () {
            this.payment.quantity = null
            this.payment.event_id = null
            this.payment.tournament_id = null
            this.payment.event_id = null
            this.payment.name = null
            this.payment.product_id = null
            this.payment.price = null
            this.payment.total = null
            this.payment.doyang_id = null
            this.payment.bundle_id = null
            this.doyangMonthlyPayment = {}
            this.event = {}
            this.exam = {}
            this.product = {}
            this.tournament = {}
            this.bundle = {}
            this.payment.quantity = null
            this.btndisable = true
            this.quantitydisable = true
            if (!this.noteHasClient) {
                this.$set(this.payment, 'user', { doyang: { monthly_fee: null } })
                this.btndisable = true
            }
            if (
                (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_MONTHLY_PAYMENT && this.payment.user.doyang.monthly_fee) ||
                (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_INSCRIPTION && this.payment.user)
            ) {
                this.btndisable = false
            }
            if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_INSURANCE_ID) {
                this.$set(this.payment, 'quantity', 1)
                this.$set(this.payment, 'price', this.insurancePrice)
                this.btndisable = true
            }
            if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_DOYANG) {
                this.$set(this.payment, 'price', this.paymentConcepts[this.envVars.PAYMENT_CONCEPT_DOYANG].price)
                this.$set(this.payment, 'quantity', 1)
            }
            if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_INSCRIPTION) {
                this.$set(this.payment, 'quantity', 1)
                this.$set(this.payment, 'product', 'INSCRIPCIÓN')
                this.$set(this.payment, 'name', 'INSCRIPCIÓN')
            }
        },
        'tournament': function () {
            if (this.tournament.id) {
                this.payment.tournament_id = this.tournament.id,
                    this.payment.name = this.tournament.name
                this.btndisable = false
                if (this.payment.tournament_id != null) {
                    this.payment.quantity = 1
                    this.payment.total = this.tournament.price * this.payment.quantity
                }
            }
        },
        'event': function () {
            if (this.event.id) {
                this.payment.event_id = this.event.id,
                    this.payment.name = this.event.name
                this.payment.price = this.event.price
                if (this.payment.event_id != null) {
                    this.payment.quantity = 1
                    this.payment.total = this.event.price * this.payment.quantity
                }
                this.btndisable = false
            }
        },
        'exam': function (newExam) {
            this.setExamDiscounts(newExam)
            if (this.exam.id) {
                this.payment.exam_id = this.exam.id,
                    this.payment.name = this.exam.name
                this.payment.price = this.exam.price
                this.payment.quantity = 1
                if (this.payment.exam_id != null) {
                    const earlyDate = this.exam?.early_payment_student_due_date?.substring(0, 10)
                    const today = (new Date()).toISOString().substring(0, 10)
                    this.isPrepay = earlyDate ? today <= earlyDate : false
                    if (earlyDate >= today) {
                        this.payment.total = this.exam.price * this.payment.quantity;
                    } else {
                        this.payment.total = this.exam.price * this.payment.quantity
                    }
                }
            }
        },
        'payment.client_doyang': function () {
            this.payment.payment_concept_id = null
        },
        'product': function () {
            if (this.product.id) {
                this.payment.product_id = this.product.id,
                    this.payment.name = this.product.name
                this.payment.price = 0
                this.payment.total = 0
                this.payment.quantity = 1
                this.quantitydisable = !this.productSize?.id
            }
        },
        'productVariant': function () {
            if (this.productVariant?.id) {
                this.productSize = null
                this.payment.price = 0
                this.payment.total = 0
                this.payment.quantity = 1
                this.quantitydisable = !this.productSize?.id
            }
        },
        'productSize': function () {
            if (this.productSize?.id) {
                this.payment.price = this.productSize.price
                const discount = (this.payment.client_doyang?.doyang_surrogation?.percentage || 0) / 100
                this.payment.total = this.productSize.price * (1 - discount)
                this.payment.quantity = 1
                this.quantitydisable = !this.productSize?.id
            }
        },
        'payment.quantity': function () {
            if (this.payment.quantity) {
                const discount = this.isSellToDoyang ? (this.payment.client_doyang?.doyang_surrogation?.percentage || 0) / 100 : 0
                if (this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_PRODUCT_ID && this.payment.name != null) {
                    this.payment.total = (this.productSize?.price * (this.payment.quantity ? this.payment.quantity : 1)) * (1 - discount)
                }
            }
        },
        'doyang': function () {
            if (this.doyangMonthlyPayment.id) {
                this.payment.doyang_id = this.doyangMonthlyPayment.id,
                    this.payment.name = this.doyangMonthlyPayment.name
                this.payment.quantity = 1
                this.payment.price = this.doyangMonthlyPayment.monthly_fee
            }
        },
        'doyangMonthlyPayment': function () {
            this.payment.doyang_id = this.doyangMonthlyPayment.id,
                this.payment.name = this.doyangMonthlyPayment.name
            this.payment.quantity = 1
            this.payment.price = this.doyangMonthlyPayment.monthly_fee
        },
        'bundle': function () {
            if (this.bundle.id) {
                this.payment.bundle_id = this.bundle.id,
                    this.payment.name = this.bundle.name
                this.payment.quantity = 1
                this.payment.total = this.bundle.price
                this.payment.price = this.bundle.price
                if (this.payment.user)
                    this.btndisable = false
            }
        },
        'payment.price': function () {
            if (this.payment) {
                if (this.payment.price != null) {
                    this.btndisable = false
                }
            }
        },
        'totalSumOfPrice': function () {
            if (this.payment?.total == undefined) this.btndisable = true
            else this.btndisable = false
            if (this.payment.payment_type == 'EFECTIVO') {
                this.cash_total = this.totalSumOfPrice
            }
            if (this.payment.payment_type == 'TARJETA DE CRÉDITO') {
                this.credit_total = this.totalSumOfPrice
            }
            if (this.payment.payment_type == 'TARJETA DE DÉBITO') {
                this.debit_total = this.totalSumOfPrice
            }
            if (this.payment.payment_type == 'DEPÓSITO') {
                this.deposit_total = this.totalSumOfPrice
            }
        }
    },
    computed: {
        totalSumOfPrice() {
            return this.noteItems.reduce((accum, item) => {
                const totalString = item.total
                if (typeof totalString === 'number') {
                    return accum + totalString
                }
                const totalOnlyNumbers = totalString.replace(/[^0-9.-]+/g, "")
                return accum + parseFloat(totalOnlyNumbers)
            }, 0)
        },
        serverTime() {
            if (!this.$store.getters['Time/time']) {
                return new Date().toISOString()
            }
            return this.$store.getters['Time/time'].split(' ').join('T')
        },
        has2Competitions() {
            if (!this.tournament) return false
            return this.numberOfModalities > 1
        },
        numberOfModalities() {
            if (!this.tournament) return 0
            let howManyCompetititions = 0;
            this.payment.pays_forms ? howManyCompetititions++ : null
            this.payment.pays_boards ? howManyCompetititions++ : null
            this.payment.pays_kicking_speed ? howManyCompetititions++ : null
            this.payment.pays_combat ? howManyCompetititions++ : null
            this.payment.pays_techniques ? howManyCompetititions++ : null
            return howManyCompetititions
        },
        lastReceiptPhone() {
            return this.lastReceipt?.payments?.[0].client_phone
        },
        isComission() {
            return this.payment?.payment_concept_id == this.envVars.PAYMENT_CONCEPT_COMISION
        },
        comissions() {
            let data = this.$store?.getters['Payment/payments']
            if (data && typeof data === 'object') {
                data = Object.values(data)
            }
            console.log(data, data)
            return data?.filter($payment => !$payment.paid_date)
        },
        surrogatedDoyangs() {
            if (ENV.DIRECCIÓN_GENERAL_ID == this.selectedDoyang.id) {
                return this.$store.getters['Doyang/doyangs']
            }
            return this.selectedDoyang.surrogated_doyangs
        },
        isGeneralDirection() {
            return this.selectedDoyang.id == ENV.DIRECCIÓN_GENERAL_ID
        },
        selectedDoyang() {
            return this.$store.getters['Auth/selectedDoyang']
        },
        students() {
            return this.$store.getters['Student/students']
        },
        paymentConcepts() {
            return this.$store.getters['PaymentConcept/paymentConcepts']
        },
        exams() {
            // if (this.isSellToDoyang && this.payment.payment_concept_id === this.envVars.PAYMENT_CONCEPT_EXAM_ID) {
            const exams = this.$store.getters['Exam/exams'];
            if (this.isPayingComission) {
                return exams.map(exam => {
                    const examDate = new Date(exam.date)
                    const today = new Date(this.serverTime)
                    if (examDate.getFullYear() == today.getFullYear()) {
                        return exam
                    }
                }).filter(exam => exam);
            }
            if (!this.isPayingComission && !this.isSellToDoyang) {
                return this.filterPassedAndActive(this.$store.getters['Exam/exams']);
            }
            return exams.map(exam => {
                const examDate = new Date(exam.date)
                const today = new Date(this.serverTime)
                if (examDate.getFullYear() == today.getFullYear()) {
                    return exam
                }
            }).filter(exam => exam);
        },
        tournaments() {
            return this.filterPassedAndActive(this.$store.getters['Tournament/tournaments']);
        },
        events() {
            return this.filterPassedAndActive(this.$store.getters['Event/events']);
        },
        products() {
            return this.$store.getters['Product/products']
        },
        doyangs() {
            if (ENV.DIRECCIÓN_GENERAL_ID == this.selectedDoyang.id) {
                return this.$store.getters['Doyang/doyangs']
            } else {
                return []
            }
        },
        bundles() {
            if (!this.$store.getters['Doyang/doyang']?.doyang_category?.bundles) {
                return [];
            }
            return this.$store.getters['Doyang/doyang'].doyang_category.bundles.filter(bundle => {
                if (this.payment.payment_concept_id == 6 && !bundle.covers_inscription) {
                    return false
                }
                if (this.serverTime.substring(0, 10) > bundle.due_date) {
                    return false
                }
                if (this.payment.payment_concept_id != 6 && bundle.covers_inscription) {
                    return false
                }
                if (this.payment.payment_concept_id != bundle.bundle_type) {
                    return false
                }
                if (bundle.ancticipated_monthly_payments && this.payment.user?.first_uncovered_month < this.serverTime.substring(0, 10)) {
                    return false
                }
                const studentCanHaveDiscount = this.payment.user?.doyang_bundle_id == bundle.id
                return Number.parseInt(bundle.for_everyone) || studentCanHaveDiscount
            })
        }
    },
    data() {
        return {
            insurancePrice: 0,
            isLoadingComissions: false,
            multiLevelObjectTotalAmount: 'N/A',
            credit_total: 0,
            debit_total: 0,
            cash_total: 0,
            deposit_total: 0,
            showMultiplePaymentMethods: false,
            canSellNextYearInsurance: false,
            colaborate_mom: null,
            colaborate_dad: null,
            colaborates: null,
            isRevisionApproved: false,
            sellLoading: false,
            resetSaleButton: false,
            currencyFormatter: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }),
            multiLevelObjectTotal: 0,
            multiLevelObject: [],
            isPayingComission: false,
            companionName: "",
            companionAge: "",
            debtExams: null,
            debtEvents: null,
            debtTournaments: null,
            debtExamsList: [],
            listCompanions: [],
            sellPackage: false,
            noteHasClient: false,
            searchStudent: "",
            bundleProductSizes: {},
            isPrepay: false,
            lastReceiptId: false,
            lastReceipt: null,
            selectedComissions: [],
            isSellToDoyang: false,
            isSellToGeneralPublic: false,
            noteItemsCounter: 0,
            envVars: {},
            btndisable: true,
            quantitydisable: true,
            event: {},
            exam: {},
            tournament: {},
            product: {},
            productVariant: {},
            productSize: {},
            monthly_fee: null,
            noteItems: [],
            doyang: {},
            bundle: {},
            expanded: [],
            isDevolution: false,
            headers: [
                { text: 'Producto', value: 'product' },
                { text: 'Cliente', value: 'full_name' },
                { text: 'Cantidad', value: 'quantity' },
                { text: 'Precio Unitario', value: 'price' },
                { text: 'Descuento', value: 'discount' },
                { text: 'Total', value: 'total' },
                { text: 'Acciones', value: 'actions' },
                { text: '', value: 'data-table-expand' },
            ],
            paymentTemplate: {
                insurance_end: null,
                user: {
                    doyang: {
                        monthly_fee: null
                    }
                },
                sell_insurance_next_year: false,
                colaborate_mom: null,
                colaborate_dad: null,
                colaborates: null,
                scolarshipped: false,
                use_recommended: false,
                monthsAdded: 0,
                pays_combat: false,
                pays_forms: false,
                companionList: [],
                total: null,
                quantity: null,
                name: null,
                payment_concept_id: null,
                exam_id: null,
                tournament_id: null,
                event_id: null,
                price: null,
                product_id: null,
                doyang_id: null,
                bundle_id: null,
                client_doyang_id: null,
                prepay_discount_meeting: false,
                prepay_discount_revision: false,
                payment_type: 'FAVOR DE DEFINIR',
            },
            payment: {
                has_optional_discount: null,
                insurance_end: null,
                user: {
                    doyang: {
                        monthly_fee: null
                    }
                },
                scolarshipped: false,
                monthsAdded: 0,
                pays_combat: false,
                pays_forms: false,
                companionList: [],
                total: null,
                quantity: null,
                name: null,
                payment_concept_id: null,
                exam_id: null,
                tournament_id: null,
                event_id: null,
                price: null,
                product_id: null,
                doyang_id: null,
                bundle_id: null,
                client_doyang_id: null,
                prepay_discount_meeting: false,
                prepay_discount_revision: false,
                use_recommended: false,
                payment_type: 'FAVOR DE DEFINIR',
            }
        }
    }
}
</script>
<style>
tr.v-data-table__expanded.v-data-table__expanded__content {
    text-indent: 16px;
    text-align: left;
    display: contents;
    box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 75%), inset 0px -4px 8px -5px rgb(50 50 50 / 75%);
    line-height: 40px;
    font-size: 14px;
}
</style>
